import { Grid, Table, Image, Divider } from "semantic-ui-react";
import React, {useState} from "react";
import "../signature.css";
import { useTranslation } from "react-i18next";
import {PageHeader} from "../components/SignoffSheetComponents"

/**
 * Returns the position in the string that would be the start of a "wrapping".  It's either the maxLength given, 
 * or the position of the space that is closest to the maxLength without going past it.
 * @param {String} txt 
 * @param {int} maxLength 
 * @param {String[1]} breakOnChar 
 * @returns int Position of anticipated break
 */
function findBreakPos(txt="", maxLength, breakOnChar=" "){
  return txt.length>maxLength ? Math.min(txt.substring(0,maxLength+1).lastIndexOf(breakOnChar), maxLength) : Math.max(txt.length-1,0);
}

/**
 * returns an array of strings, each element representing a line that would be wrapped in table cell
 * @param {String} txt The text to be wrapped.
 * @param {int} lineLength The maximum length the text can be before wrapping
 * @returns {Array[String]} An array of strings that represents each wrapped line of the given txt
 */
function wrapText(txt="", lineLength){
  var out = [];
  if(txt.length>lineLength){
    let breakAt=findBreakPos(txt,lineLength);
    out.push(txt.substring(0,breakAt+1));
    out = out.concat(wrapText(txt.substring(breakAt+1), lineLength));
  }else{
    out.push(txt);
  }
  return out;
}

const PrintTemplate = props => {
  const { t } = useTranslation();
  const fontHeight = 22;
  let [maxRowHeight, setMaxRowHeight] = useState(44);

  //checks the user's name length to see if it will fit in the printout
  props.learnerChunk.forEach(learner => {
    learner.printedFName = learner.firstName;
    let firstNameCheck = learner.printedFName.split(" ");
    firstNameCheck.forEach(name => {
      if (name.length > 13) {
        let firstHalf = name.slice(0, 12);
        let lastHalf = name.slice(12);
        let splitName = firstHalf + "- " + lastHalf;
        learner.printedFName = splitName;
      }
    });
    learner.printedLName = learner.lastName;
    let lastNameCheck = learner.printedLName.split(" ");
    lastNameCheck.forEach(name => {
      if (name.length > 13) {
        let firstHalf = name.slice(0, 12);
        let lastHalf = name.slice(12);
        let splitName = firstHalf + "- " + lastHalf;
        learner.printedLName = splitName;
      }
    });
  });

  //check each printed name and determine max row height
  //this is needed otherwise the two columns of names won't be the same height
  //even if they have the same number of rows.  leads to confusion.
  let tempMaxRowHeight=maxRowHeight;
  props.learnerChunk.forEach(learner=>{
    const name = learner.printedFName + " " + learner.printedLName;
    const wrappedName = wrapText(name, 13);
    const h = wrappedName.length * fontHeight;
    tempMaxRowHeight = Math.max(tempMaxRowHeight,h);
  })
  if(tempMaxRowHeight > maxRowHeight) setMaxRowHeight(tempMaxRowHeight);

  //split the learners into 2 groups so they can be printed in side by side tables
  let oddLearners = props.learnerChunk.filter((learner, i) => i % 2 === 1);
  let evenLearners = props.learnerChunk.filter((learner, i) => i % 2 === 0);

  return (
    <div style={{ padding: "25px" }}>
      <PageHeader title={props.title} page={props.page} pageTotal={props.pageTotal} instructorFName={props.instructorFName} instructorLName={props.instructorLName} />

      <Grid>
        <Grid.Row stretched columns={2}>
          <Grid.Column>
            <Table
              unstackable={true}
              compact
              celled
              style={{ fontSize: "10pt" }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {t(
                      "manageRoster.instructorSignOff.table.headers.learnerSignature"
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t(
                      "manageRoster.instructorSignOff.table.headers.learnersName"
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("manageRoster.instructorSignOff.table.headers.score")}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {evenLearners.map(learner => (
                  <Table.Row key={learner.userId} style={{height:maxRowHeight}}>
                    <Table.Cell>
                      <Image
                        style={{ height: "30px", width: "135px" }}
                        src={learner.signature}
                      ></Image>
                    </Table.Cell>
                    <Table.Cell>
                      {learner.printedFName + " " + learner.printedLName}
                    </Table.Cell>
                    <Table.Cell>{learner.score}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column>
            <Table
              unstackable={true}
              compact
              celled
              style={{ fontSize: "10pt" }}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {t(
                      "manageRoster.instructorSignOff.table.headers.learnerSignature"
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t(
                      "manageRoster.instructorSignOff.table.headers.learnersName"
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("manageRoster.instructorSignOff.table.headers.score")}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {oddLearners.map(learner => (
                  <Table.Row key={learner.userId} style={{height:maxRowHeight}}>
                    <Table.Cell>
                      <Image
                        style={{ height: "30px", width: "135px" }}
                        src={learner.signature}
                      ></Image>
                    </Table.Cell>
                    <Table.Cell>
                      {learner.printedFName + " " + learner.printedLName}
                    </Table.Cell>
                    <Table.Cell>{learner.score}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider hidden></Divider>
      <Divider hidden></Divider>
      <div >
        <p>{t("manageRoster.instructorSignOff.instructorSignature")}</p>
        <Image
          style={{ border: "3px solid black" }}
          src={props.instructorSignature}
        ></Image>
      </div>
    </div>
  );
};
export default PrintTemplate;
