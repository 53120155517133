import { Modal } from "semantic-ui-react";
import React from "react";
import { useTranslation } from "react-i18next";
import ClassSign from "../components/ClassSign";
const CrewSignOff = props => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const direction = i18n.dir()==="rtl" ? "rtl" : "ltr";

  let usersToSign = [];
  props.enrolledLearners.forEach(learner => {
    if (learner.signature === "" || learner.isSigning === true) {
      usersToSign.push(learner);
    }
  });

  return (
    <Modal dir={direction}
      onClose={props.onClose}
      open={props.open}
    >
      <ClassSign
        usersToSign={usersToSign}
        submitSignatures={props.submitSignatures}
        handleCancelLearner={props.handleCancelLearner}
        setAllSignedOff={props.setAllSignedOff}
        setUpdateTable={props.setUpdateTable}
        enrolledLearners={props.enrolledLearners}
        resign={false}
        onClose={props.onClose}
        open={props.open}
      />
    </Modal>
  );
};
export default CrewSignOff;
