import React, { useState } from "react";
import { Button, Modal, Divider, Dropdown, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
const ConfirmCancelFromRoster = props => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const dir = i18n.dir() === "rtl" ? "rtl" : "ltr";
  let [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Modal dir={dir}
        size="tiny"
        trigger={
          <Dropdown.Item onClick={() => setModalOpen(true)}>
            <Icon name="user cancel"></Icon>
            {t("manageRoster.rosterScreen.table.editPopupMenu.cancelLearner")}
          </Dropdown.Item>
        }
        centered={true}
        open={modalOpen}
      >
        <Modal.Header style={{ textAlign: "center", color: "red" }}>
          {t(
            "manageRoster.rosterScreen.table.editPopupMenu.cancelLearnerPopup.cancelALearnerHeaderText",
            {firstName:props.person.firstName, lastName:props.person.lastName}
          )}
        </Modal.Header>
        <Modal.Content style={{ textAlign: "center", fontSize: "13pt" }}>
          <Divider vertical hidden></Divider>
          <p>
            <b>
              {t(
                "manageRoster.rosterScreen.table.editPopupMenu.cancelLearnerPopup.cancelLearnerText"
              )}
            </b>
          </p>
          <Divider vertical hidden></Divider>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={e => {
              setModalOpen(false);
              e.stopPropagation();
            }}
          >
            {t(
              "manageRoster.rosterScreen.table.editPopupMenu.cancelLearnerPopup.buttons.back"
            )}
          </Button>
          <Divider vertical hidden></Divider>
          <Button
            primary
            onClick={e => {
              props.handleCancelLearner(props.person.userId);
              setModalOpen(false);
              e.stopPropagation();
            }}
            icon="user cancel"
            labelPosition="right"
            content={t(
              "manageRoster.rosterScreen.table.editPopupMenu.cancelLearnerPopup.buttons.cancelLearner"
            )}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
export default ConfirmCancelFromRoster;
