import React, { useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { Table, Checkbox } from "semantic-ui-react";

const SelectLocationsTable = props => {
 
  let [selectedLocations, setSelectedLocations] = useState([]);
  const { t, i18n } = useTranslation();
  const dir = i18n.dir() === "rtl" ? "rtl" : "ltr";
  const textAlign = dir==="rtl" ? "right" : "left";

  useEffect(() => {
    console.log(props.selectedStores)
    let storeList = props.selectedStores.map(store => store.name);
    setSelectedLocations(storeList);
  }, [props.selectedStores]);


  return (
    <>
      <Table
        unstackable={true}
        style={{ textAlign: textAlign}}
        singleLine
        compact
        celled
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Checkbox
                checked={props.checkAllButton}
                onChange={() => {
                  props.checkAll(!props.checkAllButton, props);
                  props.setCheckAllButton(!props.checkAllButton);
                }}
              ></Checkbox>
            </Table.HeaderCell>
            <Table.HeaderCell selectable onClick={() => {}}>
                <p>{t("manageRoster.selectOptionsModal.selectLocations.table.headers.storeName")}</p>
            </Table.HeaderCell>            
            <Table.HeaderCell selectable onClick={() => {}}>
                <p>{t("manageRoster.selectOptionsModal.selectLocations.table.headers.city")}</p>
            </Table.HeaderCell>            
            <Table.HeaderCell selectable onClick={() => {}}>
                <p>{t("manageRoster.selectOptionsModal.selectLocations.table.headers.storeNumber")}</p>
            </Table.HeaderCell>
            <Table.HeaderCell selectable onClick={() => {}}>
                <p>{t("manageRoster.selectOptionsModal.selectLocations.table.headers.address")}</p>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
                <Checkbox
                  checked={selectedLocations.indexOf(props.defaultStore.name) !== -1 }
                  onChange={() => {
                    props.updateLocation(props.defaultStore.name);
                  }}
                ></Checkbox>
            </Table.Cell>
            <Table.Cell>{props.defaultStore.name}</Table.Cell>
            <Table.Cell>{props.defaultStore.city}</Table.Cell>
            <Table.Cell>{props.defaultStore.storeNSN}</Table.Cell>
            <Table.Cell>{props.defaultStore.addr1}</Table.Cell>
          </Table.Row>
          {props.allStores.map(location => (
            <Table.Row key={location.name}>
              <Table.Cell>
                  <Checkbox
                    checked={selectedLocations.indexOf(location.name)!==-1}
                    onChange={() => {
                      props.updateLocation(location.name);
                    }}
                  ></Checkbox>
              </Table.Cell>
              <Table.Cell>{location.name}</Table.Cell>
              <Table.Cell>{location.city}</Table.Cell>
              <Table.Cell>{location.storeNSN}</Table.Cell>
              <Table.Cell>{location.addr1}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};
export default SelectLocationsTable;
