import { Button, Modal } from "semantic-ui-react";
import React, { useState, createRef, useEffect } from "react";
import "../signature.css";
import PrintTemplate from "../components/PrintTemplate";
import DisplaySignOff from "./DisplaySignOff";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line no-self-assign
function zeroAdder(time){return(time < 10 ? time ="0"+time : time = time)}
function dateGen() {
  let date = new Date();
  let currentDay = zeroAdder(date.getDate());
  let currentMonth = zeroAdder(date.getMonth() + 1);
  let currentYear = zeroAdder(date.getFullYear());
  let currentHour = zeroAdder(date.getHours());
  let currentMinute = zeroAdder(date.getMinutes());
  let currentSeconds = zeroAdder(date.getSeconds())
 let dateString =""+ currentYear + "" + currentMonth +"" +   currentDay + "_" + currentHour + "" +  currentMinute + ""+  currentSeconds;
 
  return dateString
}
function splitArray(myArray) {
  let temp_array = [];
  for (let i = 0; i < myArray.length; i += 14) {
    let chunk = myArray.slice(i, i + 14);
    temp_array.push(chunk);
  }
  return temp_array;
}
function filterLearners(items) {
  let tempArray = [];
  items.forEach(learner => {
    if (learner.complete === false && learner.signature !== "") {
      tempArray.push(learner);
    }
  });
  return tempArray;
}
const SignOffSheet = props => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  
  let [isInstructorSigned, setisInstructorSigned] = useState(false);
  let [isPrinting, setIsPrinting] = useState(false);
  const [learnersToComplete, setLearnersToComplete] = useState(filterLearners(props.enrolledLearners));
  const [chunkedLearners, setChunkedLearners] = useState(splitArray(learnersToComplete));
  //const elRef = useRef(chunkedLearners.map(() => createRef()));
  const [pageRefs, setPageRefs] = useState([]);

  const direction = i18n.dir() === "rtl" ? "rtl" : "ltr";

  let title = props.title;
  let pageTotal = chunkedLearners.length;
  let dateString = dateGen();
  let courseId = props.courseNo;
 
  // Update the learners that need to be completed when enrolledLearners is changed
  // e.g. when their signature is added.  Updating the learnersToComplete needs to
  // cascade to chunkedLearners, and then to the page refs.
  useEffect(()=>{
    setLearnersToComplete(filterLearners(props.enrolledLearners));
  },[props.enrolledLearners, props.open]);
  useEffect(()=>{
    setChunkedLearners(splitArray(learnersToComplete));
  },[learnersToComplete]);
  useEffect(()=>{
    setPageRefs(chunkedLearners.map(()=>createRef()));
  },[chunkedLearners]);
  
  const print = download => {
    document.getElementById("wrapperSignOffSheet").style.display = "block";
    //let pdftest = elRef.current;

    const pdf = new jsPDF("p", "in", [612, 792], true);
    let width = pdf.internal.pageSize.getWidth();
    let height = pdf.internal.pageSize.getHeight();
    console.log("Generating PDF...");
    let pdfGen = new Promise((resolve, reject) => {
      //This method is async, the promise is so it only resolves after all pages have been added
      let pageIncrement = 0
      let newArray = [...pageRefs]
      newArray.forEach((ref, index, array) => {
        console.log("-- page " + index+1);
        //had to add pageIncrement value instead of using array index because safari starts index at 1 instead of zero
        html2canvas(ref.current, {
          scale: 1.5,
          width: 734,
          height: 950,
          scrollX: 0,
          scrollY: 0
        }).then(async canvas => {
         
          let imgData = await canvas.toDataURL("image/png");
          if (pageIncrement !== 0) {
            await pdf.addPage();
          }
         await pdf.addImage(imgData, "PNG", 0, 0, width, height, "", "FAST");
          if (pageIncrement === array.length - 1) resolve();
          else pageIncrement++;
        });
      
      });
    });
    pdfGen.then(async () => {
      console.log("...Generated PDF");
      let pdfName = `${courseId}_${props.defaultStore.storeNSN}_${dateString}.pdf`;
      let pdfToFile = await pdf.output("datauristring");
      await props.markAllCompleteEsig(pdfToFile, pdfName);
      if (download.download === true) {
       await pdf.save(pdfName);
      }
      setIsPrinting(false);
    })
    
    document.getElementById("wrapperSignOffSheet").style.display = "none";
    //set signoff sheet element back to hidden
  };

  function close() {
    props.setUpdateTable(Date.now());
    setisInstructorSigned(false);
    props.onClose();
  }

  return (
    <Modal
      dir={direction}
      onClose={props.onClose}
      open={props.open}
    >
      <Modal.Header>{t("manageRoster.instructorSignOff.header")}</Modal.Header>
      <Modal.Content>
        <div>
          <DisplaySignOff
           instructorLName={props.instructorLName}
           instructorFName={props.instructorFName}
            isPrinting={isPrinting}
            setInstructorSignature={props.setInstructorSignature}
            instructorSignature={props.instructorSignature}
            setUpdateTable={props.setUpdateTable}
            page={"1"}
            pageTotal={chunkedLearners.length}
            title={title}
            dateString={dateString}
            learnersToComplete={learnersToComplete}
            isInstructorSigned={isInstructorSigned}
            setisInstructorSigned={setisInstructorSigned}
          ></DisplaySignOff>
        </div>
        <div
          id="wrapperSignOffSheet"
          style={{
            maxWidth: "750px",
            display: "none",
            width: "auto",
            margin: "auto"
          }}
        >
          {chunkedLearners.map((chunk, i) => (
            <div
              //ref={elRef.current[i]}
              ref={pageRefs[i]}
              style={{ pageBreakAfter: "always", width: "725px" }}
              key={i}
              id={"chunkedLearners" + i}
            >
              <PrintTemplate
               instructorLName={props.instructorLName}
               instructorFName={props.instructorFName}
                learnerChunk={chunk}
                instructorSignature={props.instructorSignature}
                pageTotal={pageTotal}
                page={i + 1}
                title={title}
                dateString={dateString}
                learnersToComplete={props.learnersToComplete}
              ></PrintTemplate>
            </div>
          ))}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            props.onClose();
            setisInstructorSigned(false);
          }}
        >
          {t("manageRoster.instructorSignOff.buttons.cancel")}
        </Button>
        <Button
              color="blue"
              style={isInstructorSigned && learnersToComplete.length>0 ? {visibility:"visible"} : {visibility:"hidden"}}
              onClick={() => {
                setIsPrinting(true);
                print({ download: true });
                close();
              }}>{t(
                "manageRoster.instructorSignOff.buttons.confirmAndDownload"
              )}
        </Button>
        <Button
              color="green"
              style={isInstructorSigned && learnersToComplete.length>0 ? {visibility:"visible"} : {visibility:"hidden"}}
              onClick={() => {
                setIsPrinting(true);
                print({ download: false });
                close();
              }}>{t(
                "manageRoster.instructorSignOff.buttons.confirm"
              )}
        </Button>        
      </Modal.Actions>
    </Modal>
  );
};
export default SignOffSheet;
