import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Table, Icon, Popup, Checkbox , Dimmer, Loader} from "semantic-ui-react";
import MissingCoursesTable from "../components/MissingCoursesTable";

const PrerequisiteTable = props => {
  const { t, i18n } = useTranslation();
  let [sorted, setSorted] = useState("");
  let [loading, setLoading]=useState(false);

  const dir = i18n.dir() === "rtl" ? "rtl" : "ltr";
  const textAlign = dir === "rtl" ? "right" : "left";

  useEffect(() => {
    console.log("prerequisite users updated")
    if(props.users.length > 0){
      setLoading(false);
    }else{
      setLoading(true);
    }
  }, [props.users]);
  return (
    <>
         
    {loading ? (
       <Dimmer active inverted>
         <Loader size="large">{t("manageRoster.selectOptionsModal.prerequisites.loadingMessage")}</Loader>
       </Dimmer>
     ) : ( 
      <Table
        unstackable={true}
        style={{ textAlign: textAlign }}
        singleLine
        compact
        celled
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell
              selectable
              onClick={() => {
                if (sorted !== "fname") {
                  props.users.sort((a, b) =>
                    a.firstName > b.firstName ? 1 : -1
                  );
                  setSorted("fname");
                } else {
                  props.users.sort((a, b) =>
                    a.firstName > b.firstName ? -1 : 1
                  );
                  setSorted("fnameR");
                }
              }}
            >
              <>
                {sorted === "fname" || sorted === "fnameR" ? <Icon fitted name={sorted === "fname" ? 'angle down' : 'angle up'} /> : ""}
                <b>{t("manageRoster.selectOptionsModal.prerequisites.table.headers.firstName")}</b>
              </>
            </Table.HeaderCell>
            <Table.HeaderCell
              selectable
              onClick={() => {
                if (sorted !== "lname") {
                  props.users.sort((a, b) =>
                    a.lastName > b.lastName ? 1 : -1
                  );
                  setSorted("lname");
                } else {
                  props.users.sort((a, b) =>
                    a.lastName > b.lastName ? -1 : 1
                  );
                  setSorted("lnameR");
                }
              }}
            >
              <>
                {sorted === "lname" || sorted === "lnameR" ? <Icon fitted name={sorted === "lname" ? 'angle down' : 'angle up'} /> : ""}
                <b>{t("manageRoster.selectOptionsModal.prerequisites.table.headers.lastName")}</b>
              </>
            </Table.HeaderCell>
            <Table.HeaderCell
              selectable
              onClick={() => {
                if (sorted !== "storeNum") {
                  props.users.sort((a, b) =>
                    a.storeNSN > b.storeNSN ? 1 : -1
                  );
                  setSorted("storeNum");
                } else {
                  props.users.sort((a, b) =>
                    a.storeNSN > b.storeNSN ? -1 : 1
                  );
                  setSorted("storeNumR");
                }
              }}
            >
              <>
                {sorted === "storeNum" || sorted === "storeNumR" ? <Icon fitted name={sorted === "storeNum" ? 'angle down' : 'angle up'} /> : ""}
                <b>{t("manageRoster.selectOptionsModal.prerequisites.table.headers.storeNumber")}</b>
              </>
            </Table.HeaderCell>
            <Table.HeaderCell
              selectable
              onClick={() => {
                if (sorted !== "address") {
                  props.users.sort((a, b) => (a.address > b.address ? 1 : -1));
                  setSorted("address");
                } else {
                  props.users.sort((a, b) => (a.address > b.address ? -1 : 1));
                  setSorted("addressR");
                }
              }}
            >
              <>
                {sorted === "address" || sorted === "addressR" ? <Icon fitted name={sorted === "address" ? 'angle down' : 'angle up'} /> : ""}
                <b>{t("manageRoster.selectOptionsModal.prerequisites.table.headers.address")}</b>
              </>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.users.map(user => (
            <Table.Row key={user.userId}>
              <Table.Cell>{props.recommended.includes(user.userId) ? (
                    <Popup style={{ textAlign }}
                      content={(<MissingCoursesTable
                        required={props.incomplete_courses}
                        employee={user.userId}
                      ></MissingCoursesTable>)}
                      on="click"
                      trigger={<Icon color="yellow" name="exclamation triangle" />}
                    />) : (null)
                }
                {
                  props.missing.includes(user.userId) ? (
                    <Popup style={{ textAlign }}
                      content={(<MissingCoursesTable
                        required={props.incomplete_courses}
                        employee={user.userId}
                      ></MissingCoursesTable>)}
                      on="click"
                      trigger={<Icon color="red" name="ban" />}
                    />) : (null)
                }</Table.Cell>
              <Table.Cell>
                {user.firstName}
              </Table.Cell>
              <Table.Cell>{user.lastName}</Table.Cell>
              <Table.Cell>{user.storeNSN}</Table.Cell>
              <Table.Cell>{user.address}</Table.Cell>
            </Table.Row>
          ))} 
          </Table.Body>
      </Table>)}
    </>
  );
};
export default PrerequisiteTable;
