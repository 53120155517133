import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/stable";

import "regenerator-runtime/runtime";
import React, {Suspense} from "react";
import "./css/index.css";
import App from "./App";
import "./i18n";
import { render } from "react-dom";
import { Loader } from "semantic-ui-react";

if (process.env.REACT_APP_CONSOLE_LOGGING === 'false') {
    console.log = () => {}
    console.warn = () => {}
    console.debug = () => {}  
}

//render(<App />, document.getElementById("root"));

render(    
    <Suspense
        fallback={
            <Loader active >&nbsp;</Loader>
        }
    >
        <App/>
    </Suspense>
    , document.getElementById("root")
)
