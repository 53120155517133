import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Table, Icon, Checkbox } from "semantic-ui-react";

const SelectLearnersTable = props => {
  const { t, i18n } = useTranslation();
  let [sorted, setSorted] = useState("");

  const dir = i18n.dir() === "rtl" ? "rtl" : "ltr";
  const textAlign = dir==="rtl" ? "right" : "left";

  useEffect(() => {
    props.setCheckedAllButton(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Table
        unstackable={true}
        style={{ textAlign: textAlign }}
        singleLine
        compact
        celled
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Checkbox
                onChange={() => {
                  props.checkAll(props.checkAllButton, props);
                  props.setCheckedAllButton(!props.checkAllButton);
                }}
              ></Checkbox>
            </Table.HeaderCell>
            <Table.HeaderCell
              selectable
              onClick={() => {
                if (sorted !== "fname") {
                  props.users.sort((a, b) =>
                    a.firstName > b.firstName ? 1 : -1
                  );
                  setSorted("fname");
                } else {
                  props.users.sort((a, b) =>
                    a.firstName > b.firstName ? -1 : 1
                  );
                  setSorted("fnameR");
                }
              }}
            >
              <>
                {sorted === "fname" || sorted === "fnameR" ? <Icon fitted name={sorted === "fname" ? 'angle down' : 'angle up'} /> : ""}
                <b>{t("manageRoster.selectOptionsModal.selectLearners.table.headers.firstName")}</b>
              </>
            </Table.HeaderCell>
            <Table.HeaderCell
              selectable
              onClick={() => {
                if (sorted !== "lname") {
                  props.users.sort((a, b) =>
                    a.lastName > b.lastName ? 1 : -1
                  );
                  setSorted("lname");
                } else {
                  props.users.sort((a, b) =>
                    a.lastName > b.lastName ? -1 : 1
                  );
                  setSorted("lnameR");
                }
              }}
            >
              <>
                {sorted === "lname" || sorted === "lnameR" ? <Icon fitted name={sorted === "lname" ? 'angle down' : 'angle up'} /> : ""}
                <b>{t("manageRoster.selectOptionsModal.selectLearners.table.headers.lastName")}</b>
              </>
            </Table.HeaderCell>
            <Table.HeaderCell
              selectable
              onClick={() => {
                if (sorted !== "storeNum") {
                  props.users.sort((a, b) =>
                    a.storeNSN > b.storeNSN ? 1 : -1
                  );
                  setSorted("storeNum");
                } else {
                  props.users.sort((a, b) =>
                    a.storeNSN > b.storeNSN ? -1 : 1
                  );
                  setSorted("storeNumR");
                }
              }}
            >
              <>
                {sorted === "storeNum" || sorted === "storeNumR" ? <Icon fitted name={sorted === "storeNum" ? 'angle down' : 'angle up'} /> : ""}
                <b>{t("manageRoster.selectOptionsModal.selectLearners.table.headers.storeNumber")}</b>
              </>
            </Table.HeaderCell>
            <Table.HeaderCell
              selectable
              onClick={() => {
                if (sorted !== "address") {
                  props.users.sort((a, b) => (a.address > b.address ? 1 : -1));
                  setSorted("address");
                } else {
                  props.users.sort((a, b) => (a.address > b.address ? -1 : 1));
                  setSorted("addressR");
                }
              }}
            >
              <>
                {sorted === "address" || sorted === "addressR" ? <Icon fitted name={sorted === "address" ? 'angle down' : 'angle up'} /> : ""}
                <b>{t("manageRoster.selectOptionsModal.selectLearners.table.headers.address")}</b>
              </>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {props.displayedLearners.map(user => (
              <Table.Row key={user.userId}>
                <Table.Cell>
                  {user.enrolled === true ? (
                    <Checkbox
                      indeterminate
                      onChange={() => {
                        props.toggle(user);
                        props.setCheckedUsers(new Date());
                      }}
                    ></Checkbox>
                  ) : user.checked === true ? (
                    <Checkbox
                      checked
                      onChange={() => {
                        props.toggle(user);
                        props.setCheckedUsers(new Date());
                      }}
                    ></Checkbox>
                  ) : (
                    <Checkbox
                      checked={false}
                      onChange={() => {
                        props.toggle(user);
                        props.setCheckedUsers(new Date());
                      }} 
                    ></Checkbox>
                  )}
                </Table.Cell>
                <Table.Cell>{user.firstName}</Table.Cell>
                <Table.Cell>{user.lastName}</Table.Cell>
                <Table.Cell>{user.storeNSN}</Table.Cell>
                <Table.Cell>{user.address}</Table.Cell>
              </Table.Row>
            ) //: (
             // <React.Fragment key={user.userId}></React.Fragment>
              // <Table.Row>
              //   <Table.Cell>No learners found for the specified Location(s)</Table.Cell>
              // </Table.Row>
           // )
          )}
        </Table.Body>
      </Table>
    </>
  );
};
export default SelectLearnersTable;
