import {
  Button, Grid, Modal, Input, Divider,
  Loader, Dimmer
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import SelectLearnersTable from "../components/SelectLearnersTable";
import SelectLocationsTable from "../components/SelectLocationsTable";
//import PrerequisiteModal from "../components/PrerequisiteModal";
import { enroll, enrollBatch, getPrerequisites } from "../../../webservices/API";


const SelectRoster = props => {
  const { t, i18n } = useTranslation();
  let [loading, setLoading] = useState(false);
  let [showModal, setShowModal] = useState(true);
  let [displayedLearners, setDisplayedLearners] = useState(props.users);
  let [, setCheckedUsers] = useState("");
  let [selectedScreen, setSelectedScreen] = useState("learners");
  let [checkAllButton, setCheckedAllButton] = useState(false);
  let [checkAllLocationsButton, setCheckAllLocationsButton] = useState(false);
  let [fNameInput, setFNameInput] = useState("");
  let [lNameInput, setLNameInput] = useState("");
  //let [selectedStores, setSelectedStores] = useState(props.selectedStores.length>=1 ? props.selectedStores : [props.defaultStore.name]);

  let direction = i18n.dir();

  /*
    async function enrollLearnerBatch(
      learners
    ) {
      try {
        let enrollmentResult = await enrollBatch(
          learners,
          props.CourseId,
        );
        return enrollmentResult;
      } catch (err) {
        props.setError(true);
        props.setErrorMessage(t("errors.couldNotEnrollLearner"));
        console.error(err);
      }
    }
    async function enrollLearner(
      userId,
      firstName,
      lastName,
      storeNumber,
      storeAddress,
      learnerNum,
      storeNSN,
      storeId
    ) {
      try {
        let enrollment = await enroll(
          userId,
          props.CourseId,
          firstName,
          lastName,
          storeNumber,
          storeAddress,
          learnerNum,
          storeNSN,
          storeId
        );
        return enrollment;
      } catch (err) {
        props.setError(true);
        props.setErrorMessage(t("errors.couldNotEnrollLearner"));
        console.error(err);
      }
  
  
    }
  */
  function updateLocation(location) {
    console.log("updatelocation", location)
    console.log("props.selectedStores", props.selectedStores)
    const selectedStores = props.selectedStores;
    let tempStore = [];
    let selected_ary = [];
    if (location === props.defaultStore.name) {
      tempStore.push(props.defaultStore);
    } else {
      tempStore = props.allStores.filter(store => store.name === location);
    }
    let locationIndex = selectedStores.findIndex(store => store.name === location)
    console.log("index", locationIndex);
    //let locationIndex = selectedStores.indexOf(location);
    selected_ary = [].concat(selectedStores);
    if (locationIndex > -1) {
      selected_ary.splice(locationIndex, 1)
    } else {
      selected_ary.push(tempStore[0]);
    }
    console.log(selected_ary)
    props.setSelectedStores(selected_ary);
  }

  function checkAllLearners(checkAllButton, myProps) {
    const selectedStores = props.selectedStores.map(store => store.name);
    console.log('checkAllLearners', myProps.users)
    if (myProps.users.length !== 0) {
      if (checkAllButton === false) {
        myProps.users.forEach(user => {
          if (selectedStores.indexOf(user.storeName) !== -1) {
            user.checked = true;
          }
        });
      } else {
        myProps.users.forEach(user => {
          if (selectedStores.indexOf(user.storeName) !== -1) {
            user.checked = false;
          }
        });
      }
    }
  }

  function checkAllLocations(checkAll, myProps) {
    if (checkAll) {
      //let allLocations_ary=[myProps.defaultStore.name];
      //allLocations_ary.push(myProps.allStores.map(store=>store.name));
      //myProps.allStores.forEach(store=>{allLocations_ary.push(store.name)});
      let allLocations_ary = [props.defaultStore];
      allLocations_ary.push(...myProps.allStores);
      props.setSelectedStores(allLocations_ary);
    } else {
      props.setSelectedStores([]);
    }
  }

  async function setPrereqs() {
    console.log("setPrereqs")
    setLoading(true);
    setShowModal(false);
    props.setIsPrerequisiteModalOpen(true)
    let prereqUsers = [];
    let incomplete = [];
    let missingRecommended = [];
    let learnersArray = [];

    for (const user of props.users) {
      if (user.checked == true) {
        learnersArray.push(user.userId);
      }
    }
    let learnersString = JSON.stringify(learnersArray);
    try {

      await getPrerequisites(props.CourseId, learnersString).then(function (result) {
        for (const course of result.msg) {
          if (course.completedRequired == false) {
            incomplete.push(course.personId);
          }

          if (course.completedRecommended == false) {
            missingRecommended.push(course.personId);
          }
        }

        props.setPrerequisites(result.msg);
        prereqUsers.push(incomplete);
        prereqUsers.push(missingRecommended);
      });
      setLoading(false);
      if (prereqUsers[0].length == 0 && prereqUsers[1].length == 0) {

        props.setIsPrerequisiteModalOpen(false)
      } else {
        props.setIsPrerequisiteModalOpen(true)
        setShowModal(false);
      }
      setCheckedAllButton(false);
      setDisplayedLearners(props.users);
      props.setButtonEmphasis(false);
      return prereqUsers;
    } catch (err) {
      props.setError(true);
      console.log('error getting prerequisites');
      console.log(err);
    }
  }

  // async function setLearnersBatch(prereqUsers) {
  //   let enrollmentsToAttempt = [];
  //   let usersToEnroll = [];
  //   let usersChecked = [];
  //   let incomplete = prereqUsers[0];
  //   let missingRecommended = prereqUsers[1];
  //   let enrolledUserIds = [];
  //   let enrolledUsers = [];
  //   console.log(prereqUsers);
  //   //set up the batch usersToEnroll array
  //   for (const user of props.users) {
  //     if ((incomplete.includes(user['userId']) != false || missingRecommended.includes(user['userId']) != false)
  //       && user.checked == true) {
  //       usersChecked.push(user);
  //     }
  //     if (
  //       user.checked === true &&
  //       (props.enrolledLearners.indexOf(user) !== -1) === false &&
  //       incomplete.includes(user['userId']) == false
  //     ) {
  //       enrollmentsToAttempt.push(user);
  //     } else if (
  //       user.checked === false &&
  //       (props.enrolledLearners.indexOf(user) !== -1) === true
  //     ) {
  //       usersToEnroll.push(user);
  //     } else if (
  //       user.checked === true &&
  //       (props.enrolledLearners.indexOf(user) !== -1) === true
  //     ) {
  //       usersToEnroll.push(user);
  //       user.enrolled = true;
  //     }
  //   }
  //   //batch the enrollmentsToAttempt (this is local DB only)
  //   try {
  //     let enrollBatchAttempt = await enrollLearnerBatch(enrollmentsToAttempt, props.CourseId);
  //     enrolledUserIds = enrollBatchAttempt.results.filter(result => result.status === 'success').map(l => l.learnerId);
  //     console.log("count from successful enrollment", enrolledUserIds.length);
  //     for (const user of props.users) {
  //       if (enrolledUserIds.includes(user.userId)) {
  //         enrolledUsers.push(user);
  //         usersToEnroll.push(user);
  //       }
  //     }
  //     console.log("total enrolledUsers after enrollment", enrolledUsers);
  //     console.log("total usersToEnroll after enrollment", usersToEnroll);
  //   } catch (err) {
  //     props.setError(true);
  //     props.setErrorMessage(t("errors.couldNotEnrollLearner"));
  //   }

  //   props.setAddLearnersButtonIsDisabled(false);
  //   props.setCheckedLearners(usersChecked);
  //   props.setEnrolledLearners(usersToEnroll);
  // }

  // async function setLearners(prereqUsers) {
  //   let usersToEnroll = [];
  //   let usersChecked = [];
  //   let incomplete = prereqUsers[0];
  //   let missingRecommended = prereqUsers[1];

  //   console.log(prereqUsers);

  //   for (const user of props.users) {
  //     // let learnersArray = [];
  //     // learnersArray.push(user.userId);
  //     // let learnersString = JSON.stringify(learnersArray);
  //     // will need to change this if 
  //     // let prereqs = await getPrerequisites(props.CourseId, learnersString);

  //     // if (prereqs[0] == false && user.checked == true) {
  //     //   // prereqUsers.push(prereqs.msg);
  //     //   incomplete.push(prereqs.msg[0].personId);
  //     //   // prereqModal = true;
  //     // } 

  //     // if (prereqs.msg[0].completedRecommended == false && user.checked == true) {
  //     //   // prereqUsers.push(prereqs.msg);
  //     //   missingRecommended.push(prereqs.msg[0].personId);
  //     //   // prereqModal = true;
  //     // } 

  //     if ((incomplete.includes(user['userId']) != false || missingRecommended.includes(user['userId']) != false) && user.checked == true) {
  //       usersChecked.push(user);
  //     }

  //     if (
  //       user.checked === true &&
  //       (props.enrolledLearners.indexOf(user) !== -1) === false &&
  //       incomplete.includes(user['userId']) == false
  //     ) {
  //       try {
  //         let enrollmentAttempt = await enrollLearner(
  //           user.userId,
  //           user.firstName,
  //           user.lastName,
  //           user.storeNumber,
  //           user.address,
  //           user.learnerNum,
  //           user.storeNSN,
  //           user.storeId
  //         );
  //         if (enrollmentAttempt.status === "success") {
  //           usersToEnroll.push(user);
  //           user.enrolled = true;
  //         } else {
  //           props.setError(true);
  //           props.setErrorMessage(t("errors.couldNotEnrollLearner"));
  //         }
  //       } catch (err) {
  //         props.setError(true);
  //         props.setErrorMessage(t("errors.couldNotEnrollLearner"));
  //       }
  //     } else if (
  //       user.checked === false &&
  //       (props.enrolledLearners.indexOf(user) !== -1) === true
  //     ) {
  //       usersToEnroll.push(user);
  //     } else if (
  //       user.checked === true &&
  //       (props.enrolledLearners.indexOf(user) !== -1) === true
  //     ) {
  //       usersToEnroll.push(user);
  //       user.enrolled = true;
  //     }
  //   }
  //   props.setAddLearnersButtonIsDisabled(false);
  //   props.setCheckedLearners(usersChecked);
  //   props.setEnrolledLearners(usersToEnroll);
  // }


  const clearText = () => {
    setLNameInput("");
    setFNameInput("");
    setDisplayedLearners(props.users);
  };

  useEffect(() => {
    if (displayedLearners.length === 0
      || JSON.stringify(displayedLearners) !== JSON.stringify(props.users)) {
      console.log("props.users updated");
      setDisplayedLearners(props.users);
    }
    setLoading(false);
  }, [props.users]);

  return (
    <Modal
      dir={direction}
      open={showModal}
      trigger={
        <Button.Group vertical>
          <Button
            primary
            onClick={() => {
              setShowModal(true);
              setSelectedScreen("locations");
            }}
          >
            {t("manageRoster.rosterScreen.buttons.selectLocation")}
          </Button>
          <Divider fitted hidden></Divider>
          <Divider fitted hidden></Divider>
          <Button
            {...(props.buttonEmphasis ? { color: "green" } : {})}
            {...(props.addLearnersButtonIsDisabled ? { disabled: true } : {})}
            onClick={() => {
              setShowModal(true);
              setSelectedScreen("learners");
            }}
          >
            {t("manageRoster.rosterScreen.buttons.addLearners")}
          </Button>
        </Button.Group>
      }
    >
      {/******************** Learners MODAL ***********************/}
      <Modal.Header>
        <Button.Group>
          <Button
            {...(selectedScreen === "locations" ? { active: true } : {})}
            size="huge"
            onClick={() => { setSelectedScreen("locations"); }}
            style={{ height: "100%", textAlign: "center" }}
          >
            {t("manageRoster.selectOptionsModal.selectLocations.header")}
          </Button>
          <Button
            {...(selectedScreen === "learners" ? { active: true } : {})}
            size="huge"
            onClick={() => {
              setLoading(true);
              props.onNextClicked();
              setSelectedScreen("learners");
            }}
            style={{ height: "100%", textAlign: "center" }}
          >
            {t("manageRoster.selectOptionsModal.selectLearners.header")}
          </Button>
        </Button.Group>
      </Modal.Header>
      {selectedScreen === "learners" ? (
        <>
          <Modal.Content>
            <Grid>
              <Grid.Row>
                <Grid.Column
                  width={1}
                  style={{ display: "block", verticalAlign: "center" }}
                >
                  <h5>{t("manageRoster.selectOptionsModal.selectLearners.filterLabel")}</h5>
                </Grid.Column>
                <Grid.Column width={3}>
                  <Input
                    placeholder={t(
                      "manageRoster.selectOptionsModal.selectLearners.searchFields.firstName"
                    )}
                    value={fNameInput}
                    size="small"
                    onChange={(_, event) => {
                      setFNameInput(event.value);
                      setDisplayedLearners(
                        props.users.filter(
                          learner =>
                            learner.firstName
                              .toLowerCase()
                              .indexOf(event.value.toLowerCase()) !== -1 &&
                            learner.lastName
                              .toLowerCase()
                              .indexOf(lNameInput.toLowerCase()) !== -1
                        )
                      );
                    }}
                    action
                  >
                    <input />
                    <Input
                      action={
                        <Button
                          primary
                          onClick={() => {
                            clearText();
                          }}
                        >
                          {t(
                            "manageRoster.selectOptionsModal.selectLearners.clearButton"
                          )}
                        </Button>

                      }
                      placeholder={t(
                        "manageRoster.selectOptionsModal.selectLearners.searchFields.lastName"
                      )}
                      value={lNameInput}
                      size="small"
                      onChange={(_, event) => {
                        setLNameInput(event.value);
                        setDisplayedLearners(
                          props.users.filter(
                            learner =>
                              learner.lastName
                                .toLowerCase()
                                .indexOf(event.value.toLowerCase()) !== -1 &&
                              learner.firstName
                                .toLowerCase()
                                .indexOf(fNameInput.toLowerCase()) !== -1
                          )
                        );
                      }}
                    ></Input>
                  </Input>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {/* <PrerequisiteModal
              onClose={() => {
                setIsPrerequisiteModalOpen(false)
                setShowModal(false);
              }}
              open={isPrerequisiteModalOpen}
              users={props.checkedLearners}
              prerequisites={props.prerequisites}
              recommended={props.missingRecommended}
            /> */}
            <Divider hidden></Divider>
            {props.loading ? (
              <Dimmer active inverted>
                <Loader size="large"></Loader>
              </Dimmer>
            ) : (
              <></>
            )}            
              <div
                style={{
                  overflowY: "scroll",
                  height: "400px",
                  display: "block",
                  backgroundColor: "#f1f1f1"
                }}
              >
                <SelectLearnersTable
                  checkAll={checkAllLearners}
                  stores={props.selectedStores}
                  toggle={props.toggle}
                  setCheckedUsers={setCheckedUsers}
                  displayedLearners={displayedLearners}
                  setCheckedAllButton={setCheckedAllButton}
                  checkAllButton={checkAllButton}
                  users={props.users}
                  prerequisites={props.prerequisites}
                ></SelectLearnersTable>
              </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              secondary
              onClick={() => {
                setShowModal(false);
                setCheckedAllButton(false);
                setDisplayedLearners(props.users);
                props.setButtonEmphasis(false);
                props.onClose();
              }}
            >
              {t(
                "manageRoster.selectOptionsModal.selectLearners.buttons.closeModalButton"
              )}
            </Button>
            {props.users.some(props.isEnrolled) ?
              <Button
                color="green"
                onClick={() => {
                  let prereqModal = setPrereqs();
                  prereqModal.then(function (res) {
                    props.setTryEnrollments(res);
                    setCheckedAllButton(false);
                    setLoading(false);
                    //setDisplayedLearners(props.users);
                    props.setButtonEmphasis(false);
                    setShowModal(false)
                  });

                }}
              >
                {t(
                  "manageRoster.selectOptionsModal.selectLearners.buttons.doneButton"
                )}
              </Button>

              : <Button disabled>{t(
                "manageRoster.selectOptionsModal.selectLearners.buttons.doneButton"
              )}</Button>

            }
          </Modal.Actions>
        </>
      ) : (
        <>
          {/******************** LOCATIONS MODAL ***********************/}
          <Modal.Content>
            <div
              style={{
                overflowY: "scroll",
                height: "400px",
                display: "block",
                backgroundColor: "#f1f1f1"
              }}
            >
              <SelectLocationsTable
                allStores={props.allStores}
                updateLocation={updateLocation}
                //tempLocations={tempLocations}
                selectedStores={props.selectedStores}
                defaultStore={props.defaultStore}
                setCheckAllButton={setCheckAllLocationsButton}
                checkAllButton={checkAllLocationsButton}
                checkAll={checkAllLocations}
              ></SelectLocationsTable>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              secondary
              onClick={() => {
                //props.setSelectedStores(selectedStores);
                setShowModal(false);
                props.onClose();
              }}
            >
              {t(
                "manageRoster.selectOptionsModal.selectLocations.buttons.closeModalButton"
              )}
            </Button>
            <Button
              primary
              onClick={() => {
                //props.setSelectedStores(selectedStores);
                console.log('clicked next button');
                setLoading(true);
                props.onNextClicked();
                setSelectedScreen("learners");
              }}
            >
              {t(
                "manageRoster.selectOptionsModal.selectLocations.buttons.nextButton"
              )}
            </Button>
          </Modal.Actions>
        </>
        // conditional here
      )


      }

    </Modal>
  );
};
export default SelectRoster;
