import React, { useState, useEffect, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "../signature.css";
import ConfirmCancel from "./ConfirmCancel";

const Signature = props => {

  const { t } = useTranslation();
  let [isClicked, setIsClicked] = useState(false);

  
  if (props.person.signature !== "" && isClicked === false) {
    setIsClicked(true);
  }
  useEffect(() => {
    if (isClicked === true) {
      capture(props.person);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked]);
  let sigCanvas = useRef(null);
  const clear = person => {
    if (isClicked === true) {
      setIsClicked(false);
      sigCanvas.current.clear();
      person.signature = "";
      props.setUpdateModal(Date.now());
    }
  };
  const capture = person => {
    if (isClicked === true) {
      let data = sigCanvas.current.toDataURL();
      person.signature = data;
      person.isSigning = true;
      props.setUpdateModal(Date.now());
    }
  };
  return (
    <>
      <ConfirmCancel
        person={props.person}
        handleCancelLearner={props.handleCancelLearner}
      />
      
      <div dangerouslySetInnerHTML={
        {__html: t("manageRoster.crewSignOffScreen.enterSigTextFull", {firstName:props.firstName, lastName:props.lastName, personId:props.personId, interpolation: {escapeValue: false}})}
      } />

      {isClicked ? (
        <div
          style={{ width: 601, height: 101 }}
          onTouchEnd={() => {
            capture(props.person);
          }}
          onClick={() => {
            capture(props.person);
          }}
        >
          <SignatureCanvas
            ref={sigCanvas}
            penColor="black"
            backgroundColor="rgb(224, 224, 224)"
            onTouchEnd={() => {
              capture(props.person);
            }}
            onClick={() => {
              setIsClicked(true);
            }}
            canvasProps={{ width: 600, height: 100, className: "clicked" }}
          />
        </div>
      ) : (
        <div
          style={{ width: 601, height: 101 }}
          onTouchEnd={() => {
            capture(props.person);
            setIsClicked(true);
          }}
          onClick={() => {
            setIsClicked(true);
            capture(props.person);
          }}
        >
          <SignatureCanvas
            penColor="black"
            backgroundColor="rgb(224, 224, 224)"
            canvasProps={{ width: 600, height: 100, className: "unclicked" }}
          />
        </div>
      )}
      <Divider hidden></Divider>
      {isClicked ? (
        <Button
          color="black"
          onClick={() => {
            clear(props.person);
          }}
        >
          {t("manageRoster.crewSignOffScreen.buttons.clearButton")}
        </Button>
      ) : (
        <Button style={{ visibility: "hidden" }}>
          {t("manageRoster.crewSignOffScreen.buttons.clearButton")}
        </Button>
      )}
      <Divider />
    </>
  );
};
export default Signature;
