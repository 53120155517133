import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Table, Icon, Popup, Checkbox } from "semantic-ui-react";

const MissingCoursesTable = props => {
    const { t, i18n } = useTranslation();
    let [sorted, setSorted] = useState("");

    const dir = i18n.dir() === "rtl" ? "rtl" : "ltr";
    const textAlign = dir === "rtl" ? "right" : "left";

    return (
        <>
            <Table
                style={{ textAlign: textAlign, border: "0px" }}
                singleLine >
                <Table.Body>
                    {props.required.map(course => (
                        props.employee == course.employee_id ? (
                            <Table.Row key={course.course_id}>
                                <Table.Cell style={{ border: "0px" }}>{ course.type == 'required' ? (
                                            <Icon color="red" name="ban" />
                                        ) : (<Icon color="yellow" name="exclamation triangle" />)}</Table.Cell>
                                <Table.Cell style={{ border: "0px" }}>{course.name}</Table.Cell>
                                <Table.Cell
                                    style={{ border: "0px" }}
                                >{course.course_number}</Table.Cell>
                            </Table.Row>
                        ) : (null)
                    ))}
                </Table.Body>
            </Table>
        </>
    );
}
export default MissingCoursesTable;