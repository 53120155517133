import React from "react";
import { Divider, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const AppHeader = props => {
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const textAlign = direction==="rtl" ? "left" : "right";

  return (
    <header
      style={{ textAlign, maxWidth: "1200px" }}
      className="App-header"
    >
      <Divider hidden></Divider>
      {props.currentView === "roster" ? (
        <Button
          color="blue"
          onClick={() => {
            props.setCurrentView("classSelect");
            props.setClassSelected({});
          }}
        >
          {t("manageRoster.rosterScreen.buttons.goBack")}
        </Button>
      ) : (
        <></>
      )}
    </header>
  );
};
export default AppHeader;
