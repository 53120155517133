//Styling
import {
  Segment,
  Button,
  Header,
  Grid,
  Dropdown,
  Divider,
  Item,
  Loader,
  Dimmer
} from "semantic-ui-react";

//Components
import SelectRoster from "./SelectRoster";
import PrerequisiteModal from "../components/PrerequisiteModal";
import CrewSignOff from "../esignature/containers/CrewSignOff";
import SignOffSheet from "../esignature/containers/SignoffSheet";
import CalendarModule from "../components/CalendarModule";
import RosterTable from "../components/RosterTable";
import RosterTableEsign from "../components/RosterTableEsign";
import CompleteLearners from "../components/CompleteLearners";
import ErrorScreen from "../../ErrorScreen";
//react Modules
import React, { useState, useEffect, useCallback } from "react";
//Libraries
import { useTranslation } from "react-i18next";
//Webservices and helper methods
import {
  batchComplete,
  batchSubmitEsign,
  batchCompleteEsign,
  cancelLearner,
  //getData,
  getInstructorStores,
  getLearnersForStores,
  enroll, enrollBatch, getPrerequisites
} from "../../../webservices/API";

//create a locations array that populates the dropdown and the selection screen. Add home store first and then the possible stores after.
let locationOptions = [];
//create the users array so the check all function has access to it. We check the length to be sure they're loaded in.
let users = [];
//toggle a user's 'checked' status on the selection screen.
function toggle(user) {
  if (user.checked === true) {
    user.checked = false;
  } else {
    user.checked = true;
  }
}

function getStoreNamesForEnrolledLearners(localDefaultStore, localLocations, localEnrolledLearners) {
  // get all storeIds for enrolled learners
  const enrolledStoreIds = localEnrolledLearners.map(learner => learner.storeId)

  // get all stores, include default
  const stores = [localDefaultStore].concat(localLocations);

  // get stores that have learners that are enrolled
  const enrolledStores = stores.filter(store => enrolledStoreIds.includes(store.storeId));

  // map the store objects to names
  const enrolledStoreNames = enrolledStores.map(store => store.name);

  return enrolledStoreNames;
}


const RosterManager = props => {
  const { t, i18n } = useTranslation();
  let [enrolledLearners, setEnrolledLearners] = useState([]);
  let [checkedLearners, setCheckedLearners] = useState([]);
  let [prerequisites, setPrerequisites] = useState([]);
  let [instructorStores, setInstructorStores] = useState([]);
  let [allUsers, setAllUsers] = useState([]);
  let [selectedStores, setSelectedStores] = useState([]);
  let [selectedLocations, setSelectedLocations] = useState([]);
  let [instructorSignature, setInstructorSignature] = useState("");
  let [pickedDate, setPickedDate] = useState(new Date());
  let [, setAllSignedOff] = useState(false);
  let [buttonEmphasis, setButtonEmphasis] = useState(true);
  let [addLearnersButtonIsDisabled, setAddLearnersButtonIsDisabled] = useState(false);
  let [loading, setLoading] = useState(true);
  let [requesting, setRequesting] = useState(false);
  let [locations, setLocations] = useState([]);
  let [defaultStore, setDefaultStore] = useState([]);
  let [instructorFName, setInstructorFName] = useState("name ");
  let [instructorLName, setInstructorLName] = useState("Not Found");
  let [isCalendarDisabled, setIsCalendarDisabled] = useState(false);
  let [isPrerequisiteModalOpen, setIsPrerequisiteModalOpen] = useState(false);
  let [tryEnrollments, setTryEnrollments] = useState([]);
  // eslint-disable-next-line no-unused-vars
  let [error, setError] = useState(false);
  let [errorMessage, setErrorMessage] = useState("");
  //passing setUpdateTable with date.now() forces the component to remount, useful where you did a minor action but want the table of learners to reflect that action.
  let [, setUpdateTable] = useState();
  let [isMarkCompleteModalOpen, setMarkCompleteModalOpen] = useState(false);
  let [isSignoffSheetModalOpen, setIsSignoffSheetModalOpen] = useState(false);
  let [isCrewSignoffModalOpen, setIsCrewSignoffModalOpen] = useState(false);
  let [isLearnersLoading, setIsLearnersLoading] = useState(true);
  let [isFetchNewLearners, setIsFetchNewLearners] = useState(true);

  let direction = i18n.dir();
  let textAlign = direction === "rtl" ? "right" : "left";

  useEffect(() => {
    if (loading === true) {
      setError(false);
      try {
        (async function awaitLocationsAndLearners() {
          console.log("awaitLocationsAndLearners")
          try {
            let data = await getInstructorStores(props.classSelected.CourseId);

            if (data.status === "error") {
              console.log("error thrown");
              setError(true);
              setErrorMessageGeneric(data.msg);
            }

            if (!data.defaultStore || !data.defaultStore[0]) throw new Error("noDefaultStore");
            setDefaultStore(data.defaultStore[0]);
            setLocations(data.stores);
            setIsCalendarDisabled(false);


            setInstructorFName(data.instructorFName);
            setInstructorLName(data.instructorLName);
            const defaultLocation = {
              key: data.defaultStore[0].name,
              text: data.defaultStore[0].name,
              value: data.defaultStore[0].name
            };
            locationOptions = [];
            locationOptions.push(defaultLocation);
            //fixes array indexing issue with dropdown component
            locationOptions.push();
            //This is for the dropdown component for listing stores.
            data.stores.forEach(location => {
              let store = {
                key: location.name,
                text: location.name,
                value: location.name
              };
              locationOptions.push(store);
            });
            // //get the learner data separately so we can pass in only the stores we want
            // //the first time through, we'll only pass the defaultStore
            // let learnerData = await getLearnersForStores(props.classSelected.CourseId, [...data.defaultStore]);

            // if (learnerData.status === "error") {
            //   console.log("error thrown");
            //   setError(true);
            //   setErrorMessageGeneric(learnerData.msg);
            // }
            // console.log("enrolledLearners:", learnerData.enrolledLearners.length)
            // // if learners already enrolled, set the selected stores
            // let enrolledIds = [];
            // if (learnerData.enrolledLearners && learnerData.enrolledLearners.length > 0) {
            //   setEnrolledLearners(learnerData.enrolledLearners);
            //   //setSelectedStores(getStoreNamesForEnrolledLearners(data.defaultStore[0], data.stores, learnerData.enrolledLearners));
            //   learnerData.enrolledLearners.forEach(learner => {
            //     enrolledIds.push(learner.userId);
            //   });
            //   // if no enrolled learners, set the default store
            // } else {
            //   //setSelectedStores([...data.defaultStore]);
            // }
            setInstructorStores([...data.defaultStore, ...data.stores])
            setSelectedStores([...data.defaultStore]);
            // users = [];
            // learnerData.enrolledLearners.map(learner => {
            //   return users.push(learner);
            // });
            // //retrieve user list and add values that are required as defaults.
            // learnerData.newLearners.map(learner => {
            //   return users.push(learner);
            // });
            // console.log("1st users", users)
            // setAllUsers(users);
            //data.newLearners = [...learnerData.newLearners];
            //data.enrolledLearners = [...learnerData.enrolledLearners];
            // let learnersArray = [];
            // users.forEach(learner => {
            //   learnersArray.push(learner.userId);
            // });
            // let learnersString = JSON.stringify(learnersArray);
            // let prerequisites = await getPrerequisites(props.classSelected.CourseId, learnersString);
            // setPrerequisites(prerequisites.msg);
            setLoading(false);
          } catch (err) {
            setError(true);
            let msg = t("errors." + err.message)
            if (!msg) msg = t("errors.couldNotLoadDataFromProfile");
            setErrorMessageGeneric(msg);
            console.log(err);
          }
        })();
      } catch (err) {
        console.log(err);
        setError(true);
        setErrorMessageGeneric(err);
      }
    }
  }, [loading, props.classSelected.CourseId, t]);

  useEffect(() => {
    console.log("selectedStores effect");
    let storeList = selectedStores.map(store => store.name);
    setSelectedLocations(storeList);
  }, [selectedStores]);

  useEffect(() => {
    console.log("selectedLocations effect");
    if (isFetchNewLearners 
      && selectedStores 
      && selectedStores.length > 0) {
      setIsFetchNewLearners(false);
      handleSelectedStoresUpdated();
    }
  }, [selectedLocations]);

  useEffect(() => {
    setLearnersBatch(tryEnrollments);
  }, [tryEnrollments])

  async function handleSelectedStoresUpdated() {
    console.log('handleSelectedStoresUpdated', selectedStores)
    setIsLearnersLoading(true);

    try {
      let learnerData = await getLearnersForStores(props.classSelected.CourseId, selectedStores);
      if (learnerData.status === "error") {
        console.log("error thrown");
        setError(true);
        setErrorMessageGeneric(learnerData.msg);
      }
      // if learners already enrolled, set the selected stores
      let enrolledIds = [];
      if (learnerData.enrolledLearners && learnerData.enrolledLearners.length > 0) {
        console.log("enrolledLearners:", learnerData.enrolledLearners.length)
        setEnrolledLearners(learnerData.enrolledLearners);
        //setSelectedStores(getStoreNamesForEnrolledLearners(data.defaultStore[0], locations, learnerData.enrolledLearners));
        learnerData.enrolledLearners.forEach(learner => {
          enrolledIds.push(learner.userId);
        });
        // if no enrolled learners, set the default store
      } else {
        //setSelectedStores([data.defaultStore[0].name]);
      }
      users = [];
      users.push(...learnerData.enrolledLearners);
      // learnerData.enrolledLearners.map(learner => {
      //   return users.push(learner);
      // });
      //retrieve user list and add values that are required as defaults.
      users.push(...learnerData.newLearners);
      // learnerData.newLearners.map(learner => {
      //   return users.push(learner);
      // });
      console.log("users", users)
      setAllUsers(users);
      //data.newLearners = [...learnerData.newLearners];
      //data.enrolledLearners = [...learnerData.enrolledLearners];
      // let learnersArray = [];
      // users.forEach(learner => {
      //   learnersArray.push(learner.userId);
      // });
      // let learnersString = JSON.stringify(learnersArray);
      // let prerequisites = await getPrerequisites(props.classSelected.CourseId, learnersString);
      // setPrerequisites(prerequisites.msg);
      setLoading(false);
      setIsLearnersLoading(false);
    } catch (err) {
      setError(true);
      let msg = t("errors." + err.message)
      if (!msg) msg = t("errors.couldNotLoadDataFromProfile");
      setErrorMessageGeneric(msg);
      console.log(err);
    }

  }

  /* McD is ok with not disabling calendar *
  useEffect(()=>{
    if(!requesting){
      console.log("checking learners");
      let disableCalendar = true;
      if(enrolledLearners){
        for(let i=0; i<enrolledLearners.length; i++){
          const learner = enrolledLearners[i];
          if(!learner.complete){
            disableCalendar=false;
            break;
          }
        }
      }
      setIsCalendarDisabled(disableCalendar);
    }
  },[isCalendarDisabled,enrolledLearners,updateTable,requesting]);
  */

  /**
   * Takes either a string or a saba error message ({errorCode:1,errorMessage:'bob'}) and sets
   * this components errorMessage string
   * @param {String|SabaErrorMessage} err 
   */
  function setErrorMessageGeneric(err) {
    if (typeof (err) === "string") setErrorMessage(err);
    else if (err && err.errorMessage) setErrorMessage(err.errorMessage);
    else setErrorMessage(JSON.stringify(err));
  }

  async function setLearnersBatch(prereqUsers) {
    let enrollmentsToAttempt = [];
    let usersToEnroll = [];
    let usersChecked = [];
    let incomplete = prereqUsers[0];
    let missingRecommended = prereqUsers[1];
    let enrolledUserIds = [];
    let enrolledUsers = [];
    console.log(prereqUsers);
    //set up the batch usersToEnroll array
    for (const user of allUsers) {
      if ((incomplete.includes(user['userId']) != false || missingRecommended.includes(user['userId']) != false)
        && user.checked == true) {
        usersChecked.push(user);
      }
      if (
        user.checked === true &&
        (enrolledLearners.indexOf(user) !== -1) === false &&
        incomplete.includes(user['userId']) == false
      ) {
        enrollmentsToAttempt.push(user);
      } else if (
        user.checked === false &&
        (enrolledLearners.indexOf(user) !== -1) === true
      ) {
        usersToEnroll.push(user);
      } else if (
        user.checked === true &&
        (enrolledLearners.indexOf(user) !== -1) === true
      ) {
        usersToEnroll.push(user);
        user.enrolled = true;
      }
    }
    //batch the enrollmentsToAttempt (this is local DB only)
    try {
      if (enrollmentsToAttempt && enrollmentsToAttempt.length > 0) {
        console.log("setLearnersBatch", props.classSelected.CourseId);
        let enrollBatchAttempt = await enrollLearnerBatch(enrollmentsToAttempt, props.classSelected.CourseId);
        enrolledUserIds = enrollBatchAttempt.results.filter(result => result.status === 'success').map(l => l.learnerId);
        console.log("count from successful enrollment", enrolledUserIds.length);
        for (const user of allUsers) {
          if (enrolledUserIds.includes(user.userId)) {
            enrolledUsers.push(user);
            usersToEnroll.push(user);
          }
        }

      }
      console.log("total enrolledUsers after enrollment", enrolledUsers);
      console.log("total usersToEnroll after enrollment", usersToEnroll);
    } catch (err) {
      setError(true);
      setErrorMessage(t("errors.couldNotEnrollLearner"));
    }

    setAddLearnersButtonIsDisabled(false);
    setCheckedLearners(usersChecked);
    setEnrolledLearners(usersToEnroll);
  }

  async function enrollLearnerBatch(
    learners, courseId
  ) {
    try {
      setRequesting(true);
      let enrollmentResult = await enrollBatch(
        learners,
        courseId
      );
      setRequesting(false);
      return enrollmentResult;
    } catch (err) {
      console.log("error detected");
      props.setError(true);
      props.setErrorMessage(t("errors.couldNotEnrollLearner"));
      console.error(err);
    }
  }

  async function handleCancelLearner(userToCancel) {
    try {
      setRequesting(true);
      let cancellation = await cancelLearner(
        userToCancel,
        props.classSelected.CourseId
      );
      if (cancellation.status === "success") {
        setEnrolledLearners(
          enrolledLearners.filter(learner => learner.userId !== userToCancel)
        );
        users.forEach(user => {
          if (user.userId === userToCancel) {
            user.checked = false;
            user.enrolled = false;
            user.signature = "";
          }
        });
        setAllUsers(users);
      } else {
        setError(true);
        setErrorMessageGeneric("User could not be cancelled");
      }
      setRequesting(false);
    } catch (err) { }
  }
  function isSigned(user) {
    return user.signature !== "" ? true : false;
  }
  function isNotComplete(user) {
    return user.complete !== true ? true : false;
  }
  function needSignature(user) {
    return user.signature === "" ? true : false;
  }
  function isEnrolled(user) {
    return user.enrolled || user.checked === true ? true : false;
  }
  async function markAllCompleteEsig(pdf, pdfName) {
    try {
      console.log("Marking all esig Complete...");
      setRequesting(true);
      let completionEsig = await batchCompleteEsign(
        enrolledLearners,
        props.classSelected.CourseId,
        JSON.stringify(pickedDate),
        pdf,
        pdfName
      );
      if (completionEsig.status === "success") {
        console.log("...Marked all esig Complete");
        enrolledLearners.forEach(learner => {
          learner.complete = true;
        });
      } else if (completionEsig.status === "error") {
        console.log("body of returned esignature completion thing1 : " + JSON.stringify(completionEsig))
        setRequesting(false);
        setError(true);
        setErrorMessageGeneric(completionEsig.msg);
      } else {
        console.log("body of returned esignature completion thing2 : " + JSON.stringify(completionEsig))
      }
      setRequesting(false);
    } catch (err) {
      console.log(err);
    }
  }
  async function markAllComplete() {
    try {
      setRequesting(true);
      let completion = await batchComplete(
        enrolledLearners,
        props.classSelected.CourseId,
        JSON.stringify(pickedDate)
      );
      if (completion.status === "success") {
        enrolledLearners.forEach(learner => {
          learner.complete = true;
        });
      } else if (completion.status === "error") {
        console.log("Error message from MAC : " + JSON.stringify(completion.msg))
        setError(true)
        setErrorMessageGeneric(completion.msg)
      }
      setRequesting(false);
    } catch (err) {
      console.log(err);
    }
  }
  async function submitSignatures() {
    try {
      setRequesting(true);
      let submission = await batchSubmitEsign(
        enrolledLearners,
        props.classSelected.CourseId
      );
      if (submission.status === "success") {
        setEnrolledLearners(enrolledLearners);
        console.log("Users signatures submitted successfully");
      } else if (submission.status === "error") {
        setError(true)
        setErrorMessageGeneric("User's signatures were not submitted successfully")
      }
      setRequesting(false);
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      {error ? (
        <ErrorScreen closable={true} setError={setError} error={errorMessage}></ErrorScreen>
      ) : (
        <></>
      )}
      {loading ? (
        <Dimmer active inverted>
          <Loader size="large">{t("manageRoster.loadingMessage")}</Loader>
        </Dimmer>
      ) : (
        <Segment
          raised
          dir={direction}
          style={{
            textAlign,
            maxWidth: "1200px",
            backgroundColor: "white",
            boxShadow: "0 0 6px 2px #888888",
            fontFamily: "Lato",
            fontSize: "14px",
            color: "rgba(0, 0, 0, 0.8)",
            fontWeight: "bold",
            width: "100%",
            display: "block",
            position: "relative"
          }}
        >
          {error ? (
            <ErrorScreen closable={true} setError={setError} error={errorMessage}></ErrorScreen>
          ) : (
            <></>
          )}
          <Grid style={{ padding: "6px" }}>
            <Grid.Row>
              <Grid.Column>
                <Header as="h3">{t("manageRoster.rosterScreen.header")}</Header>
                <Divider />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row style={{ textAlign: "center", padding: "6px" }}>
              <Grid.Column width={3}>
                <SelectRoster
                  isEnrolled={isEnrolled}
                  setError={setError}
                  setErrorMessage={setErrorMessageGeneric}
                  users={allUsers}
                  CourseId={props.classSelected.CourseId}
                  buttonEmphasis={buttonEmphasis}
                  setButtonEmphasis={setButtonEmphasis}
                  toggle={toggle}
                  defaultStore={defaultStore}
                  allStores={locations}
                  selectedStores={selectedStores}
                  setSelectedStores={setSelectedStores}
                  enrolledLearners={enrolledLearners}
                  checkedLearners={checkedLearners}
                  setEnrolledLearners={setEnrolledLearners}
                  setCheckedLearners={setCheckedLearners}
                  prerequisites={prerequisites}
                  setPrerequisites={setPrerequisites}
                  setTryEnrollments={setTryEnrollments}
                  setIsPrerequisiteModalOpen={setIsPrerequisiteModalOpen}
                  addLearnersButtonIsDisabled={addLearnersButtonIsDisabled}
                  setAddLearnersButtonIsDisabled={setAddLearnersButtonIsDisabled}
                  onNextClicked={handleSelectedStoresUpdated}
                  onClose={() => { if (!enrolledLearners || enrolledLearners.length === 0) props.cancel(); }}
                  loading={isLearnersLoading}
                ></SelectRoster>
              </Grid.Column>
              <Grid.Column width={12}>
                <Dropdown
                  placeholder={t(
                    "manageRoster.rosterScreen.storeSelectDefault"
                  )}
                  fluid
                  multiple
                  selection
                  search
                  options={locationOptions}
                  value={selectedLocations}
                  onChange={(_, { value }) => {
                    // do not allow removal of a location that has an enrolled learner

                    const enrolledStoreNames = getStoreNamesForEnrolledLearners(defaultStore, locations, enrolledLearners);

                    // set the new value of the dropdown to those stores that are required because of 
                    // enrolled learners along with the selected stores.
                    // this syntax is the "easiest" way to merge arrays without duplicates
                    const selectedStoreSet = new Set([...enrolledStoreNames, ...value]);

                    // get the difference between the new selected locations, and the original ones
                    const diff = new Set([...selectedStoreSet].filter(store => !(new Set(selectedStores).has(store))));

                    // get the stores that have been selected, but have no enrolled learners
                    const selectedStoresWithoutEnrolledLearners =
                      new Set([...selectedStoreSet].filter(store => !(new Set(enrolledStoreNames)).has(store)));


                    // only set button emphasis if the locations have changed
                    // or a location is selected that has no learners enrolled
                    const emphasizeAddLearners = diff.size > 0 || selectedStoresWithoutEnrolledLearners.size > 0
                    setButtonEmphasis(emphasizeAddLearners);

                    //disable addLearners if no stores selected
                    setAddLearnersButtonIsDisabled(selectedStoreSet.size === 0);
                    //setSelectedStores(instructorStores.filter(store => selectedStoreSet.has(store.name)));
                    setSelectedStores(instructorStores.filter(store => selectedStoreSet.has(store.name)));
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row divided>
              <Grid.Column width={2}>
                <Item>
                  <Item.Header>
                    {t(
                      "manageRoster.rosterScreen.courseInfoAccordion.courseId"
                    )}
                  </Item.Header>
                  <Item.Description style={{ color: "rgba(0,0,0,.6)", overflow: "hidden", textOverflow: "ellipsis" }}>
                    {props.classSelected.courseNo}
                  </Item.Description>
                </Item>
              </Grid.Column>

              <Grid.Column width={4}>
                <Item>
                  <Item.Header>
                    {t(
                      "manageRoster.rosterScreen.courseInfoAccordion.courseTitle"
                    )}
                  </Item.Header>
                  <Item.Description style={{ color: "rgba(0,0,0,.6)" }}>
                    {props.classSelected.courseTitle}
                  </Item.Description>
                </Item>
              </Grid.Column>

              {props.classSelected.esignature ? (
                <></>
              ) : (
                <Grid.Column width={5}>
                  <Item >
                    <Item.Header>{t("manageRoster.rosterScreen.courseInfoAccordion.date")}</Item.Header>
                    <Item.Description style={{ color: "rgba(0,0,0,.6)", whiteSpace: "nowrap" }}>
                      <CalendarModule style={{ whiteSpace: "nowrap" }}
                        pickedDate={pickedDate}
                        setPickedDate={setPickedDate}
                        disabled={isCalendarDisabled}
                      />
                    </Item.Description>
                  </Item>
                </Grid.Column>
              )}
            </Grid.Row>

            <Grid.Row>
              {requesting ? (
                <Dimmer active inverted>
                  <Loader size="large"> </Loader>
                </Dimmer>
              ) : (
                <></>
              )}
              {props.classSelected.esignature ? (
                <RosterTableEsign
                  setUpdateTable={setUpdateTable}
                  handleCancelLearner={handleCancelLearner}
                  enrolledLearners={enrolledLearners}
                  submitSignatures={submitSignatures}
                  setAllSignedOff={setAllSignedOff}
                />
              ) : (
                <RosterTable
                  setUpdateTable={setUpdateTable}
                  handleCancelLearner={handleCancelLearner}
                  enrolledLearners={enrolledLearners}
                />
              )}
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                {props.classSelected.esignature ? (
                  <div style={{ float: direction === "rtl" ? "left" : "right" }}>
                    <Button
                      color="green"
                      onClick={() => setIsSignoffSheetModalOpen(true)}
                      {...(enrolledLearners.every(isSigned) && enrolledLearners.some(isNotComplete) ? {} : { disabled: true })} >
                      {t(
                        "manageRoster.rosterScreen.buttons.instructorSignature"
                      )}
                    </Button>
                    <SignOffSheet
                      setRequesting={setRequesting}
                      markAllCompleteEsig={markAllCompleteEsig}
                      defaultStore={defaultStore}
                      courseId={props.classSelected.CourseId}
                      title={props.classSelected.courseTitle}
                      enrolledLearners={enrolledLearners}
                      instructorSignature={instructorSignature}
                      setInstructorSignature={setInstructorSignature}
                      setUpdateTable={setUpdateTable}
                      instructorLName={instructorLName}
                      instructorFName={instructorFName}
                      courseNo={props.classSelected.courseNo}
                      onClose={() => setIsSignoffSheetModalOpen(false)}
                      open={isSignoffSheetModalOpen}
                    ></SignOffSheet>

                    <Button
                      color="green"
                      onClick={() => setIsCrewSignoffModalOpen(true)}
                      {...(enrolledLearners.some(needSignature) ? {} : { disabled: true })}>
                      {t("manageRoster.rosterScreen.buttons.crewSignOff")}
                    </Button>
                    <CrewSignOff
                      submitSignatures={submitSignatures}
                      handleCancelLearner={handleCancelLearner}
                      setAllSignedOff={setAllSignedOff}
                      setUpdateTable={setUpdateTable}
                      enrolledLearners={enrolledLearners}
                      onClose={() => setIsCrewSignoffModalOpen(false)}
                      open={isCrewSignoffModalOpen}
                    />
                  </div>
                ) : (
                  <div style={{ float: direction === "rtl" ? "left" : "right" }}>
                    <Button
                      color="green"
                      onClick={() => setMarkCompleteModalOpen(true)}
                      {...(enrolledLearners.some(isNotComplete) ? {} : { disabled: true })} >
                      {t("manageRoster.rosterScreen.buttons.markComplete")}
                    </Button>
                    <CompleteLearners
                      markAllComplete={markAllComplete}
                      setUpdateTable={setUpdateTable}
                      courseNo={props.classSelected.courseNo}
                      title={props.classSelected.courseTitle}
                      onClose={() => setMarkCompleteModalOpen(false)}
                      open={isMarkCompleteModalOpen}
                    />
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <PrerequisiteModal
            onClose={() => {
              setIsPrerequisiteModalOpen(false)
              //setShowModal(false);
            }}
            open={isPrerequisiteModalOpen}
            users={checkedLearners}
            prerequisites={prerequisites}
          //recommended={missingRecommended}
          />
        </Segment>
      )}
    </>
  );
};

export default RosterManager;
