import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Cookies from "js-cookie";
import Backend from "i18next-xhr-backend";


function normalizeLocale(locale){
  try{
    return locale ? locale.replace("_","-").toLowerCase() : "en-us";
  }catch(e){
    console.log("Unable to determine locale from '" + locale + "', defaulting to en-us");
    return "en-us";
  }
}
let localeCookie = normalizeLocale(Cookies.get("saba_locale"));

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: localeCookie,
    fallbackLng: "en-us",
    lowerCaseLng: true,
    debug: false,
    backend: {
      loadPath: process.env.PUBLIC_URL + "/locales/{{lng}}/{{ns}}.json"
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    react: { 
      useSuspense: true //changed to true since we need to access i18n properties
    }
  },function(err,t){
    console.log("Locale:    " + i18n.language );
    console.log("Direction: " + i18n.dir());
  });

export default i18n;
