import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import i18next from "i18next";
import {getDateFormatForLocale} from '../../../DateFormats';

// no estonian, hindi, latvian, lithuanian, 
import { 
  arSA as ar, //arabic, ar-DZ, ar-MA
  cs, //czech
  de, //german
  el, //greek
  enUS as en, //english-us, enAU, enGB english-uk, enCA english-canada, enIN, enNZ
  es, //spanish-spain
  fi, //finnish
  fr, //french-france, frCH french-swiss, frCA french-canada
  id, //indonesion
  it, //italian
  ja, //japanese
  ko, //korean
  nb, //norwegian
  nl, //dutch, nlBE dutch-belgium
  pl, //plish
  pt, //portuguese, ptBR portuguse-brazil
  ro, //romanian
  ru, //russian
  sk, //slovakian
  sv, //swedish
  tr, //turkish
  uk, //ukranian
  zhCN as zh, //chinese-simplified, zhTW chinese-traditional
} from 'date-fns/locale';

registerLocale('ar', ar);
registerLocale('cs', cs);
registerLocale('de', de);
registerLocale('el', el);
registerLocale('en', en);
registerLocale('es', es);
registerLocale('fi', fi);
registerLocale('fr', fr);
registerLocale('id', id);
registerLocale('it', it);
registerLocale('ja', ja);
registerLocale('ko', ko);
registerLocale('nb', nb);
registerLocale('nl', nl);
registerLocale('pl', pl);
registerLocale('pt', pt);
registerLocale('ro', ro);
registerLocale('ru', ru);
registerLocale('sk', sk);
registerLocale('sv', sv);
registerLocale('tr', tr);
registerLocale('uk', uk);
registerLocale('zh', zh);
setDefaultLocale('en', en);

var PopperContainer = ({ children }) => {
  return (
    <span>
      <Icon 
        name="calendar alternate" 
        onMouseEnter={()=>{if(calendarRef && calendarRef.current) calendarRef.current.setOpen(true);}}/>
      {children}
    </span>
  );
}
PopperContainer.propTypes = {
  children: PropTypes.node
}
const calendarRef = React.createRef();

const CalendarModule = props => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  
  return (
    <>
          <DatePicker
            todayButton={<Icon name="history"/>}
            selected={props.pickedDate}
            onChange={date => props.setPickedDate(date)}
            dateFormat={getDateFormatForLocale(i18next.language)}
            locale={(""+i18next.language).replace(/(_.*)|(-.*)/g,'')} // convert en_US and en-US to en
            ref={calendarRef}
            fixedHeight
            popperPlacement="bottom-end"
            popperContainer={PopperContainer}
            {...(props.disabled ? {disabled:true} : {})}
          />
    </>
  );
};
export default CalendarModule;
