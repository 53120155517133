import { Button, Modal } from "semantic-ui-react";
import React, { useState } from "react";
import Signature from "../esignature/components/Signature";
import { useTranslation } from "react-i18next";
const UserResign = props => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  let [, setUpdateModal] = useState();
  function isSigned(user) {
    return user.signature !== "" ? true : false;
  }
  useState(() => {
    props.learner.signature = "";
  }, []);
  return (
    <>
      <Modal.Header>{t("manageRoster.crewSignOffScreen.header")}</Modal.Header>
      <Modal.Content>
        <Signature
          setUpdateModal={setUpdateModal}
          handleCancelLearner={props.handleCancelLearner}
          setUpdateTable={props.setUpdateTable}
          name={props.learner.firstName + " " + props.learner.lastName}
          personId={props.learner.userId}
          key={props.learner.userId + props.learner.firstName}
          person={props.learner}
          firstName={props.learner.firstName}
          lastName={props.learner.lastName}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={e => {
            props.setModalOpen(false);
            e.stopPropagation();
          }}
        >
          {t("manageRoster.crewSignOffScreen.buttons.cancelButton")}
        </Button>
        {isSigned(props.learner) ? (
          <Button
            positive
            onClick={e => {
              // checkLearner();
              props.setModalOpen(false);
              props.submitSignatures();
              e.stopPropagation();
            }}
            icon="checkmark"
            labelPosition="right"
            content={t("manageRoster.crewSignOffScreen.buttons.confirmButton")}
          />
        ) : (
          <Button
            disabled
            icon="checkmark"
            labelPosition="right"
            content={t("manageRoster.crewSignOffScreen.buttons.confirmButton")}
          />
        )}
      </Modal.Actions>
    </>
  );
};
export default UserResign;
