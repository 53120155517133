import {
  Segment,
  Button,
  Header,
  Grid,
  Dropdown,
  Form,
  Table,
  Modal,
  Input, Loader, Dimmer
} from "semantic-ui-react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getClasses } from "../../../webservices/API";

const ClassSelectTable = props => {
  let [showModal, setShowModal] = useState(false);
  const { t, i18n } = useTranslation();
  let [pickedCourse, setPickedCourse] = useState({});
  let [searchedClasses, setSearchedClasses] = useState([]);
  let [loadingClasses, setLoadingClasses] = useState(true);
  let [allClasses, setAllClasses] = useState([]);
  let [searchCriteria, setSearchCriteria] = useState("Title");
  //let [filterValue,setFilterValue]= useState('')

  let direction = i18n.dir();
  let textAlign = direction === "rtl" ? "right" : "left";

  useEffect(() => {
    (async function awaitClasses() {
      let classes = await getClasses();
      //only set the state variables if classes returned
      if (classes) {
        setSearchedClasses(classes);
        setAllClasses(classes);
        setLoadingClasses(false);
      }
    })();
  }, []);
  const searchOptions = [
    {
      key: "Title",
      value: "Title",
      text: t("courseSelect.dropdownSearchItems.title")
    },
    {
      key: "CourseNo",
      value: "CourseNo",
      text: t("courseSelect.dropdownSearchItems.courseId")
    }
  ];
  function clicked(e) {
    setPickedCourse(e);
    setShowModal(true);
  }
  function clearSearchClasses() {
    //20200331:gk - this is an anti-pattern but I can't find a way to directly manipulate/reset the Input value
    let input = document.getElementById("searchinput");
    input.value = "";

    setSearchedClasses(allClasses);
  }
  return (
    <Segment
      raised
      style={{
        textAlign,
        direction,
        maxWidth: "900px",
        backgroundColor: "white",
        boxShadow: "0 0 6px 2px #888888",
        fontFamily: "Lato",
        color: "rgba(0, 0, 0, 0.8)",
        fontSize: " 14px",
        fontWeight: "bold",
        width: "95%",
        display: "block",
        position: "relative"
      }}
    >
      <Modal dir={direction} size={"tiny"} open={showModal}>
        <Modal.Header>
          <p>
            <b>{t("courseSelect.modalClassSelect")}</b>
          </p>
        </Modal.Header>
        <Modal.Content >
          <div style={{ display: "flex", flexDirection: "row" }}><span>{t("courseSelect.modalCourseId")}</span><span>:</span><span>&nbsp;</span><span>{pickedCourse.courseNo}</span></div>
          <div style={{ display: "flex", flexDirection: "row" }}><span>{t("courseSelect.modalCourseTitle")}</span><span>:</span><span>&nbsp;</span><span>{pickedCourse.title}</span></div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            secondary
            onClick={() => {
              setPickedCourse("");
              setShowModal(false);
            }}
          >
            {t("courseSelect.Buttons.clearButton")}
          </Button>
          <Button
            color="green"
            onClick={() => {
              props.setClassSelected({
                courseNo: pickedCourse.courseNo,
                CourseId: pickedCourse.courseID,
                courseTitle: pickedCourse.title,
                esignature: pickedCourse.esignature
              });
              props.setCurrentView("roster");
            }}
          >
            {t("courseSelect.Buttons.openButton")}
          </Button>
        </Modal.Actions>
      </Modal>
      <Header style={{ backgroundColor: "#f1f1f1" }}>
        <Button.Group>
          <Button
            size="big"
            active
            style={{ height: "100%", textAlign: "center" }}
          >
            {t("courseSelect.MarkCompleteHeader")}
          </Button>
        </Button.Group>
      </Header>

        <Grid >
          <Grid.Row width={2}>
            <Grid.Column>
              <Header as="h4">{t("courseSelect.courseSelectHeader")}:</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Dropdown
                {...(loadingClasses ? { disabled: true } : {})}
                placeholder={t("courseSelect.dropdownSearchItems.title")}
                onChange={(_, event) => {
                  setSearchCriteria(event.value);
                  console.log(event.value);
                }}
                selection
                fluid
                options={searchOptions}
              />
            </Grid.Column>
            <Grid.Column width={9}>
              <Form>
                <Form.Field>
                  {
                    loadingClasses ? (<Input loading disabled placeholder="..." />) :
                      searchCriteria === "Title" ? (
                        <Input
                          focus
                          onChange={(_, event) => {
                            //setFilterValue(event.value)
                            setSearchedClasses(
                              allClasses.filter(training =>
                                training.title
                                  .toLowerCase()
                                  .includes(event.value.toLowerCase())
                              )
                            );
                          }}
                          placeholder={t("courseSelect.searchBoxPlaceHolder")}
                          id="searchinput"
                        ></Input>
                      ) : (
                        <Input
                          focus
                          onChange={(_, event) => {
                            //setFilterValue(event.value)
                            setSearchedClasses(
                              allClasses.filter(training =>
                                training.courseNo.includes(event.value)
                              )
                            );
                          }}
                          placeholder={t("courseSelect.searchBoxPlaceHolder")}
                          id="searchinput"
                        ></Input>
                      )
                  }

                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      <br></br>
      <div
        style={{
          overflowY: "scroll",
          height: "350px",
          display: "block",
          backgroundColor: "#f1f1f1"
        }}
      >
      {loadingClasses ? (
        <Dimmer active inverted>
          <Loader size="large"></Loader>
        </Dimmer>
      ) : (
        <Table
          unstackable={true}
          style={{ textAlign: textAlign }}
          singleLine
          compact
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {t("courseSelect.modalCourseId")}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("courseSelect.modalCourseTitle")}
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {searchedClasses.map(row => (
              <Table.Row key={row.courseID}>
                <Table.Cell>{row.courseNo}</Table.Cell>
                <Table.Cell>{row.title}</Table.Cell>
                <Table.Cell style={{ textAlign: "right" }}>
                  <Button
                    color="green"
                    size="small"
                    compact
                    onClick={() => {
                      clicked(row);
                    }}
                  >
                    {t("courseSelect.Buttons.selectButton")}
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
      </div>
      <br></br>
      <Button secondary onClick={clearSearchClasses}>
        {t("courseSelect.Buttons.clearButton")}
      </Button>
    </Segment>
  );
};

export default ClassSelectTable;
