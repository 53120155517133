import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Modal
} from "semantic-ui-react";
import PrerequisiteTable from "../components/PrerequisiteTable";

const PrerequisiteModal = props => {
  const { t, i18n } = useTranslation();
  const [isPrerequisiteModalOpen, setIsPrerequisiteModalOpen] = useState(true);
  let [showModal, setShowModal] = useState(props.open);
  const direction = i18n.dir() === "rtl" ? "rtl" : "ltr";

  const incomplete = [];
  const incomplete_courses = [];
  const missingRecommended = [];
  const recommended_courses = [];


  if (Array.isArray(props.prerequisites)) {
    props.prerequisites.forEach(course => {
      if (course.completedRequired == false) {
        incomplete.push(course.personId);
        course.prerequisites.required.forEach(value => {
          let new_entry = [];
          new_entry['employee_id'] = course.personId;
          new_entry['type'] = 'required';
          new_entry['name'] = value['title'];
          new_entry['course_number'] = value['courseNumber'];
          new_entry['course_id'] = value['courseId'];
          incomplete_courses.push(new_entry);
        });
      }
    });

    props.prerequisites.forEach(course => {
      if (course.completedRecommended == false) {
        missingRecommended.push(course.personId);
        course.prerequisites.recommended.forEach(value => {
          let new_entry = [];
          new_entry['employee_id'] = course.personId;
          new_entry['type'] = 'recommended';
          new_entry['name'] = value['title'];
          new_entry['course_number'] = value['courseNumber'];
          new_entry['course_id'] = value['courseId'];
          incomplete_courses.push(new_entry);
        });
      }
    });
  }

  return (
    <>
      <Modal dir={direction} onClose={props.onClose} open={props.open} 
      >
        <Modal.Header>
          <h3>Missing Prerequisites</h3>
        </Modal.Header>
        <Modal.Content>
          <PrerequisiteTable
            users={props.users}
            missing={incomplete}
            recommended={missingRecommended}
            incomplete_courses={incomplete_courses}
            recommended_courses={recommended_courses}
          ></PrerequisiteTable>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary
            onClick={() => {
              setIsPrerequisiteModalOpen(false);
              setShowModal(false);
              props.onClose();
            }}
          >
            {t(
              "Close"
            )}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default PrerequisiteModal;