import React , { useState, useEffect }from "react";
import { useTranslation } from "react-i18next";
import { Table, Dropdown, Icon, Popup, Responsive,Loader, Dimmer } from "semantic-ui-react";
import ScoreModal from "./ScoreModal";
import ConfirmCancelFromRoster from "./ConfirmCancelFromRoster";
const popupTrigger = (
  <span>
    <Icon name="cog"></Icon>
  </span>
);

const RosterTable = props => {

  const { t, i18n } = useTranslation();
  const dir = i18n.dir() === "rtl" ? "rtl" : "ltr";
  const textAlign = dir==="rtl" ? "right" : "left";
  let [loading, setLoading] = useState(false);
  

  useEffect(() => {
    console.log("roster table data updated...");
    if(props.enrolledLearners && props.enrolledLearners.length>0){
      setLoading(false);
    }
  }, [props.enrolledLearners]);

  return (
    <>
      <Table unstackable={true} style={{ textAlign: textAlign}} compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t("manageRoster.rosterScreen.table.headers.name")}
            </Table.HeaderCell>
            <Responsive as={Table.HeaderCell} {...Responsive.onlyComputer}>
              {t("manageRoster.rosterScreen.table.headers.id")}
            </Responsive>
            <Table.HeaderCell>
              {t("manageRoster.rosterScreen.table.headers.storeNumber")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("manageRoster.rosterScreen.table.headers.score")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("manageRoster.rosterScreen.table.headers.complete")}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("manageRoster.rosterScreen.table.headers.edit")}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>{loading ? (
            <Dimmer active inverted>
              <Loader size="large"></Loader>
            </Dimmer>
          ) : (
        <Table.Body>
          {props.enrolledLearners.map(learner => (
            <Table.Row key={learner.userId}>
              {learner.error ? (
                <Popup
                  content={learner.errorMessage}
                  trigger={
                    <Table.Cell style={{ color: "red" }}>
                      {learner.firstName + " " + learner.lastName}
                      <Icon color="red" name="exclamation circle" />
                    </Table.Cell>
                  }
                />
              ) : (
                <Table.Cell>
                  {learner.firstName + " " + learner.lastName}
                </Table.Cell>
              )}
              <Responsive as={Table.Cell} {...Responsive.onlyComputer}>
                {learner.learnerNum}
              </Responsive>
              <Table.Cell>{learner.storeNSN}</Table.Cell>
              <Table.Cell>
                {learner.score !== 0 ? (
                  learner.score
                ) : (
                  <Icon color="black" name="minus" size="small"></Icon>
                )}
              </Table.Cell>

              <Table.Cell>
                {learner.complete ? (
                  <Icon name="check" color="green"></Icon>
                ) : (
                  <Icon color="red" name="minus" size="small"></Icon>
                )}
              </Table.Cell>
              <Table.Cell>
                {learner.complete ? (
                  <Popup style={{textAlign}}
                    content={t("manageRoster.rosterScreen.table.icons.hints.learnerIsAlreadyComplete")}
                    on="click"
                    trigger={<Icon color="grey" name="cog"></Icon>}
                  />
                ) : (
                  <Dropdown
                    trigger={popupTrigger}
                    pointing="top right"
                    icon={null}
                  >
                    <Dropdown.Menu>
                      <ScoreModal
                        setUpdateTable={props.setUpdateTable}
                        learner={learner}
                      ></ScoreModal>
                      <ConfirmCancelFromRoster
                        handleCancelLearner={props.handleCancelLearner}
                        person={learner}
                      ></ConfirmCancelFromRoster>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>   )}
      </Table>
    </>
  );
};
export default RosterTable;
