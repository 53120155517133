import React, { useState } from "react";
import { Modal, Divider, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const ErrorScreen = props => {
  let [modalOpen, setModalOpen] = useState(true);
  const { t, i18n } = useTranslation();
  let direction = i18n.dir();

  return (
    <>
      <Modal dir={direction} size="tiny" centered={true} open={modalOpen}>
        <Modal.Header style={{ textAlign: "center", color: "red" }}>
          {t("errors.errorModalTitle")}
        </Modal.Header>
        <Modal.Content style={{ textAlign: "center", fontSize: "13pt" }}>
          <Divider vertical hidden></Divider>
          <p>
            <b>{props.error}</b>
          </p>
        </Modal.Content>
        <Modal.Actions>
          {props.closable ?
          <Button
            primary
            onClick={e => {
              props.setError(false);
              setModalOpen(false);
              e.stopPropagation();
            }}
          >
            {t("errors.errorModalOk")}
          </Button> :<></>}
        </Modal.Actions>
      </Modal>
    </>
  );
};
export default ErrorScreen;
