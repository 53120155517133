import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, Icon, Divider, Popup } from "semantic-ui-react";

const ConfirmCancel = props => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  const dir = i18n.dir() === "rtl" ? "rtl" : "ltr";

  let [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Modal dir={dir}
        size="tiny"
        trigger={
          <Popup
            content={t(
              "manageRoster.rosterScreen.table.editPopupMenu.cancelLearner"
            )}
            trigger={
              <Icon
                link
                size="large"
                style={{ float: dir==="rtl" ? "left" : "right" }}
                name="trash"
                onClick={() => setModalOpen(true)}
              ></Icon>
            }
          />
        }
        centered={true}
        open={modalOpen}
      >
        <Modal.Header style={{ textAlign: "center", color: "red" }}>
          {t(
            "manageRoster.rosterScreen.table.editPopupMenu.cancelLearnerPopup.cancelALearnerHeaderText",
            {firstName:props.person.firstName, lastName:props.person.lastName}
          )}
        </Modal.Header>
        <Modal.Content style={{ textAlign: "center", fontSize: "13pt" }}>
          <Divider vertical hidden></Divider>
          <p>
            <b>
              {t(
                "manageRoster.rosterScreen.table.editPopupMenu.cancelLearnerPopup.cancelLearnerText"
              )}
            </b>
          </p>

          <Divider vertical hidden></Divider>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setModalOpen(false)}>
            {t(
              "manageRoster.rosterScreen.table.editPopupMenu.cancelLearnerPopup.buttons.back"
            )}
          </Button>
          <Divider vertical hidden></Divider>

          <Button
            primary
            onClick={() => {
              props.handleCancelLearner(props.person.userId);
              setModalOpen(false);
            }}
            icon="user cancel"
            labelPosition="right"
            content={t(
              "manageRoster.rosterScreen.table.editPopupMenu.cancelLearnerPopup.buttons.cancelLearner"
            )}
          />
        </Modal.Actions>
      </Modal>
    </>
  );
};
export default ConfirmCancel;
