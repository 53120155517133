import { Modal, Dropdown, Icon } from "semantic-ui-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import UserResign from "../components/UserResign";
const ResignContainer = props => {
  const { t, i18n } = useTranslation();
  let [modalOpen, setModalOpen] = useState(false);
  let dir = i18n.dir() === "rtl" ? "rtl" : "ltr";

  return (
    <Modal dir={dir}
      trigger={
        <Dropdown.Item onClick={() => setModalOpen(true)}>
          <Icon name="pencil"></Icon>
          {t("manageRoster.rosterScreen.table.editPopupMenu.reSign")}
        </Dropdown.Item>
      }
      centered={true}
      open={modalOpen}
    >
      <UserResign
        setModalOpen={setModalOpen}
        submitSignatures={props.submitSignatures}
        handleCancelLearner={props.handleCancelLearner}
        setAllSignedOff={props.setAllSignedOff}
        setUpdateTable={props.setUpdateTable}
        enrolledLearners={props.enrolledLearners}
        learner={props.learner}
      />
    </Modal>
  );
};
export default ResignContainer;
