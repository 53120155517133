//const basePath = "https://trainingtracker-dev.mcd.com";
const basePath = process.env.REACT_APP_TT_API_URL;
//OLD
export async function getData(courseid) {
  let url = `${basePath}/data`;
  let request = await fetch(url, {
    method: "GET",
    headers: {
      courseid: courseid
    },
    credentials: "include"
  });
  let response = await request.json();
  return response;
}

//NEW AMI
export async function getInstructorStores(courseid) {
  let url = `${basePath}/instructorStores`;
  let request = await fetch(url, {
    method: "GET",
    headers: {
      courseid: courseid
    },
    credentials: "include"
  });
  let response = await request.json();
  return response;
}

export async function getLearnersForStores(courseid, stores) {
  console.log(stores);
  let data = stores.map(store => store.storeId);
  console.log(data)
  let reqBody = JSON.stringify({
    stores: [...stores]
  });
  let url = `${basePath}/learners`;
  let request = await fetch(url, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      courseid: courseid,
     // stores: data.join(',')
    },
    body: reqBody,
    credentials: "include"
  });
  let response = await request.json();
  return response;
}


//BACK TO ORIGINAL
export async function getClasses() {
  let url = `${basePath}/classes`;
  let request = await fetch(url, {
    method: "GET",
    credentials: "include"
  });
  let response = await request.json();
  return response.courses;
}

export async function getPrerequisites(
  courseId,
  learners
) {
  let url = `${basePath}/enrollments/prerequisites`;
  let request = await fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      courseId: encodeURIComponent(courseId),
      learners: encodeURIComponent(learners)
    }
  });
  //console.log(request.headers);
  let response = await request.json();
  return response;
}

export async function enroll(
  learnerId,
  courseId,
  firstName,
  lastName,
  storeNumber,
  storeAddress,
  learnernum,
  storeNSN,
  storeId
) {

  let url = `${basePath}/enrollments/enroll`;
  
  let request = await fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      learnerId: encodeURIComponent(learnerId),
      courseId: encodeURIComponent(courseId),
      firstname: encodeURIComponent(firstName),
      lastname: encodeURIComponent(lastName),
      storenumber: encodeURIComponent(storeNumber),
      storeaddress: encodeURIComponent(storeAddress),
      learnernum: encodeURIComponent(learnernum),
      storeNSN: encodeURIComponent(storeNSN),
      storeId: encodeURIComponent(storeId)
    }
  });
  //console.log(request.headers);
  let response = await request.json();
  return response;
}

export async function enrollBatch(learners, courseId) {
  let url = `${basePath}/enrollments/enrollBatch`;
  let filteredLearners = [];
  learners.forEach(learner => {
    //if (learner.complete === false) {
      filteredLearners.push(learner);
    //}
  });
  let request = await fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      courseId: courseId,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      learners: filteredLearners
    })
  });

  let response = await request.json();
  return response;
}

export async function cancelLearner(learnerId, courseId) {
  let url = `${basePath}/enrollments/cancel`;

  let request = await fetch(url, {
    credentials: "include",
    method: "DELETE",
    headers: {
      learnerId: learnerId,
      courseId: courseId
    }
  });
  let response = await request.json();
  return response;
}

export async function getPermissions() {
  let url = `${basePath}/permissions`
  let request = await fetch(url, {
    method: "GET",
    credentials: "include"
  });
  let response = await request.json();
  return response
}
export async function batchSubmitEsign(learners, courseId) {
  let url = `${basePath}/esignature/esign`;
  let request = await fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      courseId: courseId,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      learners: learners
    })
  });

  let response = await request.json();
  console.log("response from batch complete esign : ");
  console.log(response);
  return response;
}
export async function batchComplete(learners, courseId, completionDate) {
  let url = `${basePath}/enrollments/complete`;
  let filteredLearners = [];
  learners.forEach(learner => {
    if (learner.complete === false) {
      filteredLearners.push(learner);
    }
  });
  let request = await fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      courseId: courseId,
      completionDate: completionDate,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      learners: filteredLearners
    })
  });

  let response = await request.json();
  return response;
}
export async function batchCompleteEsign(
  learners,
  courseId,
  completionDate,
  pdf,
  pdfName
) {
  let reducedLearners = [];
  learners.forEach(user => {
    if (user.complete === false) {
      let learner = {
        userId: user.userId,
        score: user.score
      };
      reducedLearners.push(learner);
    }
  });
  let url = `${basePath}/esignature/complete`;
  let request = await fetch(url, {
    credentials: "include",
    method: "POST",
    headers: {
      courseId: courseId,
      pdfName: pdfName,
      completiondate: completionDate,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      learners: reducedLearners,
      pdfSignOff: pdf
    })
  });

  let response = await request.json();
  return response;
}
