import { Button, Grid, Table, Image, Divider } from "semantic-ui-react";
import React, { useRef, useEffect } from "react";
import "../signature.css";
import SignatureCanvas from "react-signature-canvas";
import { useTranslation } from "react-i18next";
import {PageHeader} from "../components/SignoffSheetComponents"

const DisplaySignOff = props => {
  
  const { t } = useTranslation();
  let oddLearners = props.learnersToComplete.filter(
    (learner, i) => i % 2 === 1
  );
  let evenLearners = props.learnersToComplete.filter(
    (learner, i) => i % 2 === 0
  );

  let sigCanvas = useRef(null);

  useEffect(() => {
    if (props.isInstructorSigned === true) {
      capture(props.instructorSignature);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isInstructorSigned]);
  const clear = () => {
    if (props.isInstructorSigned === true) {
      props.setisInstructorSigned(false);
      sigCanvas.current.clear();
      props.setInstructorSignature("");
      props.setUpdateTable(Date.now());
    }
  };
  const capture = () => {
    if (props.isInstructorSigned === true) {
      let data = sigCanvas.current.toDataURL();
      props.setInstructorSignature(data);
      props.setUpdateTable(Date.now());
    }
  };

  return (
    <>

      <PageHeader title={props.title} page={props.page} pageTotal={props.pageTotal} instructorLName={props.instructorLName} instructorFName={props.instructorFName}/>

      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Table unstackable={true} compact celled style={{ pageBreakInside: "avoid" }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {t(
                      "manageRoster.instructorSignOff.table.headers.learnerSignature"
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t(
                      "manageRoster.instructorSignOff.table.headers.learnersName"
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("manageRoster.instructorSignOff.table.headers.score")}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {evenLearners.map(learner => (
                  <Table.Row key={learner.userId}>
                    <Table.Cell>
                      <Image
                        style={{ height: "30px", width: "135px" }}
                        src={learner.signature}
                      ></Image>
                    </Table.Cell>
                    <Table.Cell>
                      {learner.firstName + " " + learner.lastName}
                    </Table.Cell>
                    <Table.Cell>{learner.score}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column>
            <Table unstackable={true} compact celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {t(
                      "manageRoster.instructorSignOff.table.headers.learnerSignature"
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t(
                      "manageRoster.instructorSignOff.table.headers.learnersName"
                    )}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("manageRoster.instructorSignOff.table.headers.score")}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {oddLearners.map(learner => (
                  <Table.Row key={learner.userId}>
                    <Table.Cell>
                      <Image
                        style={{ height: "30px", width: "135px" }}
                        src={learner.signature}
                      ></Image>
                    </Table.Cell>
                    <Table.Cell>
                      {learner.firstName + " " + learner.lastName}
                    </Table.Cell>
                    <Table.Cell>{learner.score}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider hidden></Divider>
      <Divider hidden></Divider>
      <div style={{ breakInside: "avoid" }}>
        <p>{t("manageRoster.instructorSignOff.instructorSignature")}</p>
        {props.isInstructorSigned ? (
          props.isPrinting ? (
            <div
              style={{
                width: 601,
                height: 101,
                textAlign: "left",
                pageBreakInside: "avoid"
              }}
              onTouchEnd={() => {
                capture(props.instructorSignature);
              }}
              onClick={() => {
                capture(props.instructorSignature);
              }}
            >
              <SignatureCanvas
                ref={sigCanvas}
                style={{ pageBreakInside: "avoid" }}
                penColor="black"
                backgroundColor="rgb(224, 224, 224)"
                onClick={() => {
                  props.setisInstructorSigned(true);
                }}
                canvasProps={{ width: 600, height: 100, className: "printing" }}
              />{" "}
            </div>
          ) : (
            <div
              style={{
                width: 601,
                height: 101,
                textAlign: "left",
                pageBreakInside: "avoid"
              }}
              onTouchEnd={() => {
                capture(props.instructorSignature);
              }}
              onClick={() => {
                capture(props.instructorSignature);
              }}
            >
              <SignatureCanvas
                ref={sigCanvas}
                style={{ pageBreakInside: "avoid" }}
                penColor="black"
                backgroundColor="rgb(224, 224, 224)"
                onClick={() => {
                  props.setisInstructorSigned(true);
                }}
                canvasProps={{ width: 600, height: 100, className: "clicked" }}
              />{" "}
            </div>
          )
        ) : (
          <div
            style={{ width: 601, height: 101 }}
            onTouchEnd={() => {
              props.setisInstructorSigned(true);
              capture(props.instructorSignature);
            }}
            onClick={() => {
              props.setisInstructorSigned(true);
              capture(props.instructorSignature);
            }}
          >
            <SignatureCanvas
              penColor="black"
              backgroundColor="rgb(224, 224, 224)"
              canvasProps={{ width: 600, height: 100, className: "unclicked" }}
            />
          </div>
        )}
        <Divider hidden></Divider>
        {props.isInstructorSigned ? (
          props.isPrinting ? (
            <></>
          ) : (
            <Button
              style={{ textAlign: "left" }}
              color="black"
              onClick={() => {
                clear(props.instructorSignature);
              }}
            >
              {t("manageRoster.instructorSignOff.buttons.clear")}
            </Button>
          )
        ) : (
          <Button style={{ visibility: "hidden" }}>
            {t("manageRoster.instructorSignOff.buttons.clear")}
          </Button>
        )}
      </div>
    </>
  );
};
export default DisplaySignOff;
