import { Button, Modal } from "semantic-ui-react";
import React, { useState } from "react";
import Signature from "../components/Signature";
import { useTranslation } from "react-i18next";
const ClassSign = props => {
  // eslint-disable-next-line no-unused-vars
  const { t, i18n } = useTranslation();
  let [, setUpdateModal] = useState();
  function isSigned(user) {
    return user.signature !== "" ? true : false;
  }
  function checkLearners() {
    props.setUpdateTable(Date.now());
    if (props.enrolledLearners.every(isSigned) === true) {
      props.setAllSignedOff(true);
    }
  }
  return (
    <>
      <Modal.Header>{t("manageRoster.crewSignOffScreen.header")}</Modal.Header>
      <Modal.Content>
        {props.usersToSign.map(person => (
          <Signature
            setUpdateModal={setUpdateModal}
            handleCancelLearner={props.handleCancelLearner}
            name={person.firstName + " " + person.lastName}
            personId={person.learnerNum}
            key={person.userId + person.firstName}
            person={person}
            firstName={person.firstName}
            lastName={person.lastName}
          />
        ))}
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={e => {
            //first clear each signature
            props.usersToSign.forEach(user=>{user.signature=""});
            props.onClose();
            e.stopPropagation();
          }}
        >
          {t("manageRoster.crewSignOffScreen.buttons.cancelButton")}
        </Button>
        <Button
          positive
          onClick={() => {
            checkLearners();
            props.usersToSign.forEach(learner => {
              learner.isSigning = false;
            });
            props.submitSignatures();
            props.onClose();
          }}
          icon="checkmark"
          labelPosition="right"
          content={t("manageRoster.crewSignOffScreen.buttons.confirmButton")}
          {...(props.usersToSign.every(isSigned) ? {} : {disabled:true})}
        />
      </Modal.Actions>
    </>
  );
};
export default ClassSign;
