import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "semantic-ui-react";

const CompleteLearners = props => {
  const { t, i18n } = useTranslation();
  const direction = i18n.dir();
  const textAlign = direction==="rtl" ? "left" : "right";

  return (
    <>
      <Modal
        dir={direction}
        style={{ textAlign: "center" }}
        size="tiny"
        centered={true}
        onClose={props.onClose}
        open={props.open}
      >
        <Modal.Header>
          {t("manageRoster.rosterScreen.completionModal.headerWithCourseNumber", {courseNumber:props.courseNo})}
          <br></br>
          {props.title}
        </Modal.Header>

        <Modal.Actions style={{ textAlign }}>
          <Button
            secondary
            onClick={e => {
              props.onClose();
              e.stopPropagation();
            }}
          >
            {t(
              "manageRoster.rosterScreen.table.editPopupMenu.enterAdditionalInfoPopup.buttons.cancelButton"
            )}{" "}
          </Button>
          <Button
            color="green"
            onClick={e => {
              props.markAllComplete();
              props.setUpdateTable(new Date());
              props.onClose();
              e.stopPropagation();
            }}
          >
            {t("manageRoster.rosterScreen.completionModal.complete")}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
export default CompleteLearners;
