import React from "react";
import { Grid, Label, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";


function datePick(locale="en-us"){
    const options = {year: 'numeric', month: '2-digit', day: '2-digit',hour:"2-digit", minute:"2-digit", second:"2-digit",hourCycle:"h24"}
    let date = new Date(Date.now()).toLocaleDateString(locale, options)
    return date
}

const PageHeader = props=>{
    const { t } = useTranslation();

    return(
        <>
            <TitleAndPage title={props.title} page={props.page} pageTotal={props.pageTotal} />

            <p>{t("manageRoster.instructorSignOff.textDisclaimer")}</p>

            <CourseInfo title={props.title} instructorFName={props.instructorFName} instructorLName={props.instructorLName} />
        </>
    )
}
const TitleAndPage = props => {
    const { t } = useTranslation();
    return(
        <Table unstackable={true} compact celled style={{ breakInside: "avoid" }}>
        <Table.Body>
          <Table.Row>
            <Table.Cell style={{display:"flex"}}>
              <span>{t("manageRoster.instructorSignOff.topRow.title")}:</span>
              <span>&nbsp;</span>
              <span><strong>{props.title}</strong></span>
            </Table.Cell>
            <Table.Cell dir="ltr">{/*we force "ltr" because we want to display exactly what is in the translation*/}
              {t("manageRoster.instructorSignOff.topRow.pageLocation", {pageNumber:props.page, pageTotal:props.pageTotal})}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    )
}
const CourseInfo = props => {
    const { t, i18n } = useTranslation();
    const direction = i18n.dir()==="rtl" ? "rtl" : "ltr";
    const infosetsLabelTextAlign = direction==="rtl" ? "left" : "right";
    const infosetsValueTextAlign = direction==="rtl" ? "right" : "left";
    const instructorName = props.instructorFName + " " + props.instructorLName;
    // this is to handle the use case of an RTL name containing non-rtl numbers and punctuation
    // the html2pdf doesn't handle that well.
    const forceLtr = direction==="rtl" && instructorName.match(/[0-9]/g);

    return(
        <Grid>
        <Grid.Row>
          <Grid.Column style={{ textAlign: infosetsLabelTextAlign}} width={4}>
            {t("manageRoster.instructorSignOff.infoSets.courseTitle")}:
          </Grid.Column>
          <Grid.Column style={{ textAlign: infosetsValueTextAlign }} width={6}>
            <Label basic>{props.title}</Label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column style={{ textAlign: infosetsLabelTextAlign }} width={4}>
            {t("manageRoster.instructorSignOff.infoSets.instructorName")}:
          </Grid.Column>
          <Grid.Column dir={forceLtr ? "ltr" : direction} style={{ textAlign: infosetsValueTextAlign }} width={6}>
            <Label basic>{instructorName}</Label>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column style={{ textAlign: infosetsLabelTextAlign }} width={4}>
            {t("manageRoster.instructorSignOff.infoSets.dateStamp")}:
          </Grid.Column>
          <Grid.Column style={{ textAlign: infosetsValueTextAlign }} width={6}>
            <Label basic>{datePick(i18n.language)}</Label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
}

export {
    PageHeader
}