import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Modal,
  Divider,
  Input,
  Dropdown,
  Icon
} from "semantic-ui-react";

const ScoreModal = props => {
  const { t, i18n } = useTranslation();
  let [modalOpen, setModalOpen] = useState(false);
  let [value, setValue] = useState(props.learner.score);
  const direction = i18n.dir();
  
  const re = /^[0-9\b]+$/;
  function setScore() {
    if (isNaN(value)) {
      props.setUpdateTable(Date.now());
    } else {
      props.learner.score = parseInt(value, 10);
      props.setUpdateTable(Date.now());
    }
  }
  return (
    <>
      <Modal
        dir={direction}
        size="tiny"
        trigger={
          <Dropdown.Item onClick={() => setModalOpen(true)}>
            <Icon name="keyboard outline"></Icon>
            {t(
              "manageRoster.rosterScreen.table.editPopupMenu.enterAdditionalInfo"
            )}
          </Dropdown.Item>
        }
        centered={true}
        open={modalOpen}
      >
        <Modal.Header>
          {t(
              "manageRoster.rosterScreen.table.editPopupMenu.enterAdditionalInfoPopup.enterAScoreHeaderText"
              , {firstName: props.learner.firstName, lastName: props.learner.lastName}
            )}
        </Modal.Header>
        <Modal.Content>
          <Input
            type="number"
            label={t(
              "manageRoster.rosterScreen.table.editPopupMenu.enterAdditionalInfoPopup.score"
            )}
            value={value}
            onChange={e => {
              re.test(e.target.value) &&
              e.target.value <= 100 &&
              e.target.value >= 0
                ? setValue(e.target.value)
                : console.log("enter a number");
            }}
            focus={true}
          ></Input>
          <Divider hidden></Divider>
        </Modal.Content>
        <Modal.Actions >
          <Button
            secondary
            onClick={e => {
              setModalOpen(false);
              e.stopPropagation();
            }}
          >
            {t(
              "manageRoster.rosterScreen.table.editPopupMenu.enterAdditionalInfoPopup.buttons.cancelButton"
            )}{" "}
          </Button>
          <Button
            color="green"
            onClick={e => {
              props.setUpdateTable();
              setScore();
              setModalOpen(false);
              e.stopPropagation();
            }}
          >
            {t(
              "manageRoster.rosterScreen.table.editPopupMenu.enterAdditionalInfoPopup.buttons.submitButton"
            )}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};
export default ScoreModal;
