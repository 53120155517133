import React from "react";
import ClassSelectTable from "../components/ClassSelectTable";


function ClassSelect(props) {
  return (
    <>
      <header>
        <span
          style={{
            display: "table-cell",
            padding: "19px",
            textAlign: "center"
          }}
        ></span>
      </header>
      <ClassSelectTable
        setClassSelected={props.setClassSelected}
        setCurrentView={props.setCurrentView}
      ></ClassSelectTable>
    </>
  );
}

export default ClassSelect;
