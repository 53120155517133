import React, { useState, Suspense, useEffect } from "react";
import RosterManager from "./components/rosterManager/containers/RosterManager";
import { Dimmer, Loader } from "semantic-ui-react";
import ClassSelect from "./components/classSelect/containers/ClassSelect";
import AppHeader from "./components/rosterManager/components/AppHeader";
import ErrorScreen from "./components/ErrorScreen";
import { getPermissions } from "./webservices/API";
import { useTranslation } from "react-i18next";
import 'semantic-ui-css/semantic.min.css';
import "./css/App.css";


//There really should be some dynamic way to get this info.
const packageName = "---     @rapidld/mcd-training-tracker-frontend";
const packageDesc = "--- SabaCloud microapp application to Track Training";
const packageVersion = process.env.REACT_APP_VERSION || 'Unknown Version'
console.log("-----------------------------------------------------");
console.log(packageName);
console.log(packageDesc);
console.log("---                  " + packageVersion);
console.log("-----------------------------------------------------");

function App() {
  let [classSelected, setClassSelected] = useState({});
  let [currentView, setCurrentView] = useState("classSelect");
  let [error, setError] = useState(false);
  let [errorMessage, setErrorMessage] = useState("");
  let [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();


  (async () => {
    if (i18n.dir() === "rtl") {
      // eslint-disable-next-line
      const css = await import('semantic-ui-css/semantic.rtl.min.css');
      // eslint-disable-next-line
      const css2 = await import('./css/App.rtl.css')

      if (process.env.NODE_ENV === "development") {
        // eslint-disable-next-line
        //const css3 = await import('./css/App-mirror.css');
      }
    }
  })();

  useEffect(() => {
    if (loading === true) {
      (async function awaitUserPermissions() {

        let permission = await getPermissions()
        if (permission.status === "error") {
          setLoading(false)
          setError(true)
          let msg = t("errors.instructorMissingPermissions");//permission.msg ? "Unable to get user permissions: " + permission.msg : "Currently Unavailable";
          setErrorMessage(msg);
          console.log("Instructor missing permissions:");
          console.log(permission);
        }
        else if (permission.status === "success") {
          setLoading(false)
          console.log("User has permission");
        } else {
          setLoading(false);
          setError(true);
          //setErrorMessage("Currently Unavailable")
          setErrorMessage(t("errors.unableToGetUserPermission"));
          console.log("Unable to get user Permissions:");
          console.log(permission);
        }


      })()
    }
  }, [loading, error, errorMessage, t])
  return (
    <Suspense
      fallback={
        <Dimmer active inverted>
          <Loader size="large">{t('manageRoster.loadingMessage')}</Loader>
        </Dimmer>
      }
    >
      {loading ? <Loader active>{t('manageRoster.loadingMessage')}</Loader> :
        <div className="App" dir={i18n.dir() === "rtl" ? "rtl" : "ltr"} >
          {error ? (
            <ErrorScreen setError={setError} closable={false} error={errorMessage}></ErrorScreen>
          ) : (
            <>
              <AppHeader
                currentView={currentView}
                setClassSelected={setClassSelected}
                setCurrentView={setCurrentView}
              ></AppHeader>
              {currentView === "classSelect" ? (
                <ClassSelect
                  setClassSelected={setClassSelected}
                  setCurrentView={setCurrentView}
                ></ClassSelect>
              ) : (
                <RosterManager classSelected={classSelected} cancel={() => setCurrentView('classSelect')}></RosterManager>
              )}
            </>
          )}
          <footer
            style={{
              width: "100%",
              verticalAlign: "bottom",
              fontSize: "14px",
              color: "black",
              position: "relative",
              textAlign: "center",
              paddingTop: "10px"
            }}
          >
            {t("app.copyright")}
          </footer>
        </div>
      }
    </Suspense>
  );
}

export default App;
